import React from "react";

const Progressbar = (props) => {
    return ( <
        div className = "w-full h-3 bg-[#C5D2D9] mt-6 relative rounded" >
        <
        div className = "h-3 absolute left-0 bg-[#70B6AF] rounded transition-all duration-500"
        style = {
            { width: `${props.percentage}%` } } > < /div> <
        /div>
    )
}

export default Progressbar;
