import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CHECKOUT_BY_ID } from '../queries/get-checkout-by-id';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetState,
  updateLoading,
  updateMiniCart
} from '../store/shopifySlice';

const CartMini = (props) => {
  const checkoutId = useSelector((state) => state.shopify.id);
  const doUpdateMiniCart = useSelector((state) => state.shopify.doUpdateMiniCart);
  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(GET_CHECKOUT_BY_ID, {
    variables: { checkoutId: checkoutId },
    enabled: false,
    skip: checkoutId === 0
  });

  useEffect(() => {
    // reset the loading
    dispatch(updateLoading(false));

    if (data) {
      if (!data.node) {
        dispatch(resetState());
      }
    }

    if (data && data.node && data.node.completedAt && data.node.createdAt) {
      const completed = new Date(data.node.completedAt);
      const created = new Date(data.node.createdAt);

      if (created < completed) {
        // reset the checkout id if completed
        dispatch(resetState());
      }
    }

    if (doUpdateMiniCart) {
      dispatch(updateMiniCart(false));
      refetch();
    }
  }, [error, data, doUpdateMiniCart]);

  if (loading) return "";
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );

  if (data && data.node && data.node.lineItems) {
    const { edges } = data.node.lineItems;
    if (!edges.length) return "";

    const extraMiniCartElement = document.querySelector('.mobile-shopify-mini-cart');
    extraMiniCartElement.innerHTML = `<div class="cart-amount">${edges.length}</div>`;

    if (edges.length) {
      return <div className="cart-amount">{edges.length}</div>
    }
  }


  return (
    <></>
  );
};

export default CartMini;
