export const GET_RELATED = `
  query getRelated($id: [QueryArgument], $siteId: [QueryArgument]) {
    entries(section: "products", id: $id, siteId: $siteId) {
      ... on products_products_Entry {
        relatedProductsTitle
        shopifyRelatedProducts(limit: 3) {
          id
          title
          url
          ... on products_products_Entry {
            intro
            label
            shopifyProductId
            shopifyStorefrontVariantId
            productImages(limit: 1) {
              title
              url
            }
            routine {
              ... on routine_BlockType {
                morning
                evening
              }
            }
          }
        }
      }
    }
  }
`;
