import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_TO_CART } from '../queries/add-to-cart';
import { CREATE_CHECKOUT } from '../queries/create-checkout';
import { useDispatch, useSelector } from 'react-redux';
import { updateId, updateTotalPrice, updateWebUrl } from '../store/shopifySlice';
import GtmHelper from '../helpers/GtmHelper';
import LineItemsHelper from '../helpers/LineItemsHelper';
import ShopifyHelper from '../helpers/ShopifyHelper';
import {trackAddedToCart} from '../helpers/KlaviyoOnsite';

const CheckoutHandler = (props) => {
  const [addToCart] = useMutation(ADD_TO_CART);
  const [createCheckout] = useMutation(CREATE_CHECKOUT);
  const checkoutId = useSelector((state) => state.shopify.id);
  const dispatch = useDispatch();
  const currencyCode = useSelector((state) => state.shopify.currencyCode);

  useEffect(() => {
    const variantId = ShopifyHelper.getStorefrontId(props.variantId);
    let options = {
      variables: {
        lineItems: [{ variantId: variantId, quantity: props.getQuantity() }],
      },
    };

    if (!checkoutId || checkoutId === 0) {
      createCheckout(options).then((res) => {
        const {data: { checkoutCreate: { checkout: { id, webUrl, totalPriceV2, lineItems } } }} = res;

        dispatch(updateId(id));
        dispatch(updateWebUrl(webUrl));
        dispatch(updateTotalPrice(totalPriceV2));

        const totalItems = LineItemsHelper.getNumberOfItemsInCheckout(lineItems);
        const product = GtmHelper.extractProduct(
          variantId,
          res,
          "checkoutCreate"
        );
        props.setPopup({
          product,
          totalPrice: totalPriceV2,
          totalItems: totalItems,
          currencyCode
        });
        trackAddedToCart(product, props.getQuantity(), props.productUrl, webUrl);
        GtmHelper.crossSellGTM(product, props);

      });
    } else {
      options.variables["checkoutId"] = checkoutId;
      addToCart(options).then((res) => {
        const {data: { checkoutLineItemsAdd: { checkout: { webUrl, totalPriceV2, lineItems } } }} = res;
        dispatch(updateId(checkoutId));
        dispatch(updateTotalPrice(totalPriceV2));
        const totalItems = LineItemsHelper.getNumberOfItemsInCheckout(lineItems);

        const product = GtmHelper.extractProduct(
          variantId,
          res,
          "checkoutLineItemsAdd"
        );
        props.setPopup({
          product,
          totalPrice: totalPriceV2,
          totalItems: totalItems,
          currencyCode,
        });
        trackAddedToCart(product, props.getQuantity(), props.productUrl, webUrl);
        GtmHelper.crossSellGTM(product, props);

      });
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: currencyCode,
        add: {
          actionField: {
            list: 'Shopping cart',
          },
          products: [{ variantId: props.variantId, quantity: 1 }],
        },
      },
    });
  }, []);

  return <></>;
};

export default CheckoutHandler;
