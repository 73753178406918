import { gql } from "@apollo/client";
import { LINEITEM_CHECKOUT_FRAGMENT, PRICE_FRAGMENT, CHECKOUT_FRAGMENT } from "./fragments";

export const ADD_TO_CART = gql`
  ${LINEITEM_CHECKOUT_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ... CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
