import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { updateFavorites } from '../store/shopifySlice';

class FavoriteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorited: props.isFavorited,
    };

    this.handleClick = this.handleClick.bind(this);
    this.generateStyle = this.generateStyle.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    const { element } = this.props;

    if (element) {
      element.style.background = "inherit";
      const { fill, stroke } = this.generateStyle();

      ReactDOM.render(
        <div className="favorite-icon" onClick={this.handleClick}>
          <svg
            width="20px"
            height="18px"
            fill={fill}
            viewBox="0 0 53 47"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m47.5 23.217c-5.5 7-20 21-21 21-1 0-15-14-21-21-2.5-3.4999-2.5-5.4999-2.5-7.9999v-1e-4c-2e-5 -5.5757 4-9 4-9s1.5-2 5.5-3c1-0.24999 4.5-0.70755 9 1.5 2.245 1.1013 3.5 3 5 4.5 1.5-1.5 2.755-3.3987 5-4.5 4.5-2.2075 8-1.5326 9-1.2826 4 0.99996 5.5 3 5.5 3s4 3.207 4 8.7827c0 2.5-0.5357 5.5-2.5 8z"
              stroke={stroke}
              strokeLinecap="round"
              strokeWidth="5"
            />
          </svg>
        </div>,
        element
      );
    }
  }

  generateStyle() {
    const { isFavorited } = this.state;
    const fill = isFavorited ? "red" : "none";
    const stroke = isFavorited ? "red" : "#3E8F94";

    return { fill: fill, stroke: stroke };
  }

  changeView() {
    const { element } = this.props;
    if (element) {
      const heartElement = element.querySelector(".favorite-icon");
      const { fill, stroke } = this.generateStyle();
      heartElement.querySelector("svg").setAttribute("fill", fill);
      heartElement.querySelector("svg > path").setAttribute("stroke", stroke);
    }
  }

  handleClick() {
    const { localStorageKey, productId } = this.props;
    const { isFavorited } = this.state;
    this.setState({ isFavorited: !isFavorited }, this.changeView);

    // check if favorites are already present in localstorage
    let favoriteIds = JSON.parse(localStorage.getItem(localStorageKey));

    // toggle the isfavorite
    if (isFavorited) {
      favoriteIds = favoriteIds.filter(
        (favoriteId) => favoriteId !== productId
      );
    } else {
      // init empty array if it doesn't exist
      if (favoriteIds === null) {
        favoriteIds = [];
      }

      favoriteIds.push(productId);
    }

    // save in localstorage
    localStorage.setItem(localStorageKey, JSON.stringify(favoriteIds));
    this.props.updateFavorites();
  }


  render() {
    if (!this.props.element) {
      const { fill, stroke } = this.generateStyle();
      return (
        <div className="absolute top-1.25 md:top-2.5 right-1.25 md:right-3.5 z-[2]">
          <div className="favorite-icon" onClick={this.handleClick}>
            <svg
              width="20px"
              height="18px"
              fill={fill}
              viewBox="0 0 53 47"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m47.5 23.217c-5.5 7-20 21-21 21-1 0-15-14-21-21-2.5-3.4999-2.5-5.4999-2.5-7.9999v-1e-4c-2e-5 -5.5757 4-9 4-9s1.5-2 5.5-3c1-0.24999 4.5-0.70755 9 1.5 2.245 1.1013 3.5 3 5 4.5 1.5-1.5 2.755-3.3987 5-4.5 4.5-2.2075 8-1.5326 9-1.2826 4 0.99996 5.5 3 5.5 3s4 3.207 4 8.7827c0 2.5-0.5357 5.5-2.5 8z"
                stroke={stroke}
                strokeLinecap="round"
                strokeWidth="5"
              />
            </svg>
          </div>
        </div>
      )
    } else {
      return <></>;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFavorites: () => dispatch(updateFavorites())
  }
}

export default connect(null, mapDispatchToProps)(FavoriteButton);
