import React from 'react';
import DiscountIcon from '../../components/icons/discount';
import CloseIcon from '../../components/icons/close';
import Message from '../../components/Message';

const CartDiscounts = (props) => {
  const {
    discountCode,
    activeDiscountCode,
    discountErrors
  } = props;

  return (
    <>
      <form onSubmit={ props.handleAddDiscountCode } className={ 'flex mt-2' }>
        <input
          type="text"
          className={ 'form-control border border-green form-control rounded-l-md pl-2' }
          onChange={ e => props.setDiscountCode(e.target.value) }
          value={ discountCode }
          placeholder={ window.shopTranslations.addDiscountCodePlaceholder }
          required
        />
        <button type="submit"
                className={ `button button--green flex-shrink-0 mt-0 relative -left-[3px]` }>
          { window.shopTranslations.addCodeToCart }
        </button>
      </form>
      {activeDiscountCode && <div className={'mt-4 inline-block'}>
        <div title={activeDiscountCode} className={'bg-grey-light p-2 flex items-center'}>
          <DiscountIcon />

          {activeDiscountCode}

          <a href="" className={'flex ml-3'} onClick={(e) => props.removeDiscountCode(e)}>
            <CloseIcon small={true} />
          </a>
        </div>
      </div>}

      {discountErrors.length > 0 &&
        <div className={'mt-4'}>
          {discountErrors.map((discountError, index) =>
            <Message key={index} message={discountError.message} />
          )}
        </div>
      }
    </>
  );
};

export default CartDiscounts;
