import React, { useEffect, useState } from 'react';
import DiscountIcon from '../icons/discount';
import ShopifyHelper from '../../helpers/ShopifyHelper';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import { GET_URL_BY_SHOPIFY_ID } from '../../queries/get-url-by-shopify-id';
import axios from 'axios';
import EditProductInCart from './EditProductInCart';
import LoadingSpinner from '../loadingSpinner';
import ProductPromotions from './ProductPromotions';
import RoutineIcons from '../icons/Routine';
import { useSelector } from 'react-redux';

const ProductInCart = (props) => {

  const [url, setUrl] = useState('');
  const [image, setImage] = useState(false);
  const [routine, setRoutine] = useState(false);
  const [multiBuy, setMultiBuy] = useState(false);
  const [loading, setLoading] = useState(false);
  const { product } = props;
  const title = product.title;

  const currencyCode = useSelector((state) => state.shopify.currencyCode);

  const { id, priceV2, compareAtPriceV2 } = product.variant;
  const shopifyId = ShopifyHelper.getShopifyId(id);
  const priceColor = compareAtPriceV2 || props.promotions.amount > 0 ? 'text-red-500' : '';
  const isFreeProduct = Number(priceV2.amount) === 0.0;
  let price = CurrencyHelper.GetFormattedPrice(priceV2);
  let promotionPrice = false;
  if (compareAtPriceV2) {
    promotionPrice = CurrencyHelper.GetFormattedPrice(compareAtPriceV2)
  }
  if (props.promotions.amount > 0 && !compareAtPriceV2) {
    price = CurrencyHelper.GetFormattedPrice({
      amount: priceV2.amount - (props.promotions.amount / product.quantity),
      currencyCode: currencyCode
    })
    promotionPrice = CurrencyHelper.GetFormattedPrice(priceV2)
  }

  useEffect(() => {
    setLoading(true)
    axios.post(
      '/craft-api',
      {
        query: GET_URL_BY_SHOPIFY_ID,
        variables: {
          id: shopifyId,
          siteId: window.currentSiteId,
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).then(res => {
      if (res && res.data && res.data.data && res.data.data.entries && res.data.data.entries.length > 0) {
        if (res.data.data.entries[0].url) setUrl(res.data.data.entries[0].url);
        if (res.data.data.entries[0].productImages) setImage(res.data.data.entries[0].productImages[0]);

        if (res.data.data.entries[0].routine && res.data.data.entries[0].routine.length) {
          if (res.data.data.entries[0].routine[0].morning.length || res.data.data.entries[0].routine[0].evening.length) {
            setRoutine(res.data.data.entries[0].routine[0]);
          }
        }
        if (res.data.data.entries[0].multiBuy && window.multiBuyEnabled) {
          setMultiBuy(res.data.data.entries[0].multiBuy);
        }
      }
      setLoading(false)
    })
  }, []);

  if(loading) return <div className="relative min-h-32 bg-green-100 mt-8 first:mt-0"><div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 px-6 flex items-center justify-center"><LoadingSpinner/></div></div>;

  return (
    <>
      <div data-product-row="" className="py-8 border-t border-gray-200 first:pt-0 first:border-t-0">
        <div className="flex justify-between items-center md:px-8 w-full">
            <div className={ 'mr-4 flex-shrink-0' }>
              <a href={url}>
                { image ?
                  <img className="w-[62px] md:w-[130px]" style={{'aspectRatio':'1/1','objectFit':'contain'}} src={image.url} alt={ image.title } />
                  :
                  product.variant.image && <img className="w-[62px] md:w-[130px]" style={{'aspectRatio':'1/1','objectFit':'contain'}} src={product.variant.image.transformedSrc} alt={ product.variant.image.altText } />
                }
              </a>
            </div>
          <div className="self-start mr-6 md:mr-10 mt-1.5">
            {routine ?
              <RoutineIcons routine={routine} orientation={"vertical"}></RoutineIcons>
              : <div className="w-12"></div>
            }
          </div>
          <div className="lg:flex flex-grow items-center">
            <div className="lg:w-1/2 flex md:block">
              {url ?
                <a className='block flex-grow md:inline' href={url}>
                  <div className="font-bold">{title}{product.variant.title !== 'Default Title' ? ` - ${product.variant.title}` : ''}</div>
                </a> :
                <div className="block flex-grow md:inline font-bold">{title}{product.variant.title !== 'Default Title' ? ` - ${product.variant.title}` : ''}</div>
              }

              <div className='text-right md:hidden'>
                {
                  !isFreeProduct &&
                  <div className={`${priceColor}`}>
                    { (props.promotions.amount > 0 || props.promotionSubTotal) &&
                      <div className={ 'font-bold text-xl md:text-4xl' }>{ CurrencyHelper.GetFormattedPrice({
                        amount: Number(props.subTotal.amount) - Number(props.promotions.amount),
                        currencyCode: currencyCode
                      }) }</div> }
                    {
                      props.promotionSubTotal ?
                        <strong className={'block line-through text-sm md:text-md opacity-50'}>{CurrencyHelper.GetFormattedPrice(props.promotionSubTotal)}</strong>
                        :
                        <strong className={'block text-xl md:text-4xl'}>{CurrencyHelper.GetFormattedPrice(props.subTotal)}</strong>
                    }
                  </div>
                }
              </div>

              <div className={`hidden md:flex mt-2 ${priceColor}`}>
                <div className="font-bold text-sm md:text-xl">{price}</div>
                {promotionPrice &&
                  <span className="ml-2.5 line-through text-sm md:text-xl opacity-50">{ promotionPrice }</span>
                }
              </div>
              <div className="hidden md:block">
              {!isFreeProduct &&
                <>
                  <ProductPromotions
                    promotions={props.promotions}
                    multiBuy={multiBuy}
                    quantity={product.quantity}
                  />
                  <button
                    onClick={ (event) => props.handleDelete(event, product.id) }
                    className="text-sm underline text-green-darkest font-normal mt-2"
                  >
                    { window.shopTranslations.cartDelete }
                  </button>
                </>
              }
              </div>
            </div>
            <div className="flex items-center justify-between lg:w-1/2">
              <div className="md:w-1/2 flex flex-wrap md:flex-nowrap lg:justify-center mt-3 lg:mt-0">
                <EditProductInCart
                  id={ product.id }
                  price={product.variant.priceV2.amount}
                  variantId={ product.variant.id }
                  quantity={ product.quantity }
                  freeProducts={ props.freeProducts }
                  cartItemsFreeProducts={ props.cartItemsFreeProducts }
                  refetch={ props.refetch }
                  checkPromo={props.checkPromo}
                />
                <div className="md:hidden w-full">
                  {!isFreeProduct &&
                    <>
                      <button
                        onClick={ (event) => props.handleDelete(event, product.id) }
                        className="text-sm underline text-green-darkest font-normal mt-2"
                      >
                        { window.shopTranslations.cartDelete }
                      </button>
                    </>
                  }
                </div>
              </div>
              <div className="md:w-1/2 text-right pl-2 hidden md:block">
                {
                  !isFreeProduct &&
                  <div className={`flex items-center justify-end ${priceColor}`}>
                    { (props.promotions.amount > 0 || props.promotionSubTotal) &&
                      <div className={ 'font-bold text-xl md:text-4xl mr-2.5' }>{ CurrencyHelper.GetFormattedPrice({
                        amount: Number(props.subTotal.amount) - Number(props.promotions.amount),
                        currencyCode: currencyCode
                      }) }</div> }
                    {
                      props.promotionSubTotal ?
                        <strong className={'line-through text-sm md:text-md opacity-50'}>{CurrencyHelper.GetFormattedPrice(props.promotionSubTotal)}</strong>
                        :
                        <strong className={'text-xl md:text-4xl'}>{CurrencyHelper.GetFormattedPrice(props.subTotal)}</strong>
                    }
                  </div>
                }
                {props.promotions && props.promotions.amount > 0 &&
                  <p className={'hidden md:inline-block mt-1 text-sm text-grey'}>
                    <DiscountIcon /> {props.promotions.title}
                  </p>
                }
                { isFreeProduct && <strong className={'text-xl md:text-4xl'}>{CurrencyHelper.GetFormattedPrice(props.subTotal)}</strong> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductInCart;
