export default class PriceHelper {
  static DiscountHelper = (price, compareAtPrice) => {
    if (isNaN(+price) || isNaN(+compareAtPrice)) {
      console.log(`input error price = ${price} compareAtPrice=${compareAtPrice}`);
      return false;
    }
    return (price - compareAtPrice === 0 ? 0 : 100 * Math.abs((price - compareAtPrice) / compareAtPrice)).toFixed(0) || 'input error';
  }

  static PercentageHelper = (total, maxPrice) => {
    if (total >= maxPrice) {
      return 100;
    }
    return (total / maxPrice) * 100;
  }

  static PercentageItemsHelper = (totalItems, minItems) => {
    if (totalItems >= minItems) {
      return 100;
    }
    return (totalItems / minItems) * 100;
  }
}
