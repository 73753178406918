import React from "react";

const CheckMark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 12.677">
      <path fill="currentColor"
            d="M5.774 12.429a.85.85 0 0 0 1.2 0l9.775-9.775a.85.85 0 0 0 0-1.2l-1.2-1.2a.85.85 0 0 0-1.2 0L6.375 8.221 2.653 4.499a.85.85 0 0 0-1.2 0l-1.2 1.2a.85.85 0 0 0 0 1.2Z" />
    </svg>
  )
}

export default CheckMark;
