import React from 'react';

const OrderButton = (props) => {
  return (
    <a
      href={ props.url }
      onClick={ () => props.clickHandler }
      className="button mt-3 button--green w-full md:w-auto"
    >
      {props.label}
      <div className={"ml-3"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.353"><path d="M6.697 1.026a.839.839 0 0 0 .014 1.206l4.237 4.038H.848a.842.842 0 0 0-.844.844v1.125a.842.842 0 0 0 .844.844h10.1l-4.237 4.035a.845.845 0 0 0-.014 1.208l.78.78a.84.84 0 0 0 1.192 0l6.835-6.836a.84.84 0 0 0 0-1.192L8.669.248a.84.84 0 0 0-1.192 0Z" fill="currentColor"/></svg>
      </div>
    </a>
  )
}

export default OrderButton;
