import { gql } from "@apollo/client";

export const GET_PRICE_OF_VARIANT = gql`
  query GetVariantById($id: ID!) {
    node(id: $id) {
      ... on Product {
        title
      }
      ... on ProductVariant {
        quantityAvailable
        availableForSale
        currentlyNotInStock
        compareAtPriceV2 {
          amount
          currencyCode
        }
        priceV2 {
          amount
          currencyCode
        }
      }
    }
  }
`;
