import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import ShopifyHelper from '../helpers/ShopifyHelper';
import { GET_PRICE_OF_VARIANT } from '../queries/get-price-of-variant';
import CurrencyHelper from '../helpers/CurrencyHelper';
import PriceHelper from '../helpers/PriceHelper';
import VariantContext from './VariantContext';
import OutOfStockButton from './OutOfStock/OutOfStockButton';
import AddToCartButton from './AddToCartButton';

const ShowPrice = (props) => {

  const variantId = ShopifyHelper.getStorefrontId(props.variantId);
  const injectBuyButton = props.element.dataset.injectButton === 'true';
  const {
    loading,
    error,
    data
  } = useQuery(GET_PRICE_OF_VARIANT, {
    variables: {
      id: variantId
    }
  });

  const {
    contextVariant,
    setContextVariant
  } = useContext(VariantContext);

  if (loading) return '';
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );


  if (data && data.node && data.node.priceV2) {
    const rootWrapper = document.getElementById(`buy-button-wrapper-${props.variantId}`);
    const relatedAddToCartButtons = document.querySelectorAll(`.add-to-cart-button[data-variant-id="${props.variantId}"]`);
    const relatedQuantitySelector = document.querySelectorAll(`.add-to-cart-quantity[data-variant-id="${props.variantId}"]`);
    const inStockElement = document.getElementById(`shopify-stock-status-${props.variantId}`);

    const isInStock = (data.node.quantityAvailable > 0 || data.node.currentlyNotInStock);
    let price = data.node.priceV2;
    if (props.isDetailPrice && contextVariant.node) {
      price = contextVariant.node.priceV2;
    }
    //set price
    const priceWrapper = document.createElement('div');
    if (!props.isDetailPrice && props.showBackground === 'true') {
      priceWrapper.className = 'flex justify-center items-center w-full';
    }

    priceWrapper.innerHTML = `<span>${CurrencyHelper.GetFormattedPrice(price)}</span>`;

    // check if price per application is there
    const pricePerParentElement = document.querySelector(
      '.price-per-application'
    );

    // check if applicationAmount is filled in and valid
    if (
      pricePerParentElement !== null &&
      props.variantId === pricePerParentElement.getAttribute('data-variant-id') &&
      pricePerParentElement.querySelector('.amount').innerHTML !== ''
    ) {
      let appAmount = parseInt(
        pricePerParentElement.querySelector('.amount').innerHTML
      );
      const priceAmount = parseInt(data.node.priceV2.amount);
      const pricePer = CurrencyHelper.GetFormattedPrice({
        amount: priceAmount / appAmount,
        currencyCode: 'EUR'
      });

      const element = pricePerParentElement.querySelector('.content');
      element.innerHTML = pricePer;

      pricePerParentElement
        .querySelector('.wrapper')
        .classList
        .remove('hidden');
    }

    // set discount price, if applicable
    if (data.node.compareAtPriceV2 || (contextVariant.node && contextVariant.node.compareAtPriceV2)) {
      const originalPrice = document.createElement('span');
      const discountLabel = document.createElement('span');
      let compareAtPrice = data.node.compareAtPriceV2;
      if (props.isDetailPrice && contextVariant.node) {
        compareAtPrice = contextVariant.node.compareAtPriceV2;
      }
      originalPrice.innerHTML = CurrencyHelper.GetFormattedPrice(compareAtPrice);
      originalPrice.classList.add('ml-1', 'xs:ml-2.5', 'line-through', 'text-sm', 'md:text-xl', 'opacity-70');


      priceWrapper.append(originalPrice);

      if (props.showBackground === 'false') {
        const percentage = PriceHelper.DiscountHelper(price.amount, compareAtPrice.amount);
        discountLabel.innerHTML = `-${percentage}%`;
        discountLabel.classList.add('label', 'ml-2.5');
        if (!isInStock) {
          discountLabel.classList.add('bg-gray-200');
        }
        priceWrapper.append(discountLabel);
      }


      if (!props.isDetailPrice) {
        if (props.showBackground === 'true') {
          props.element.classList.add('bg-red-500');
        }
        if (props.showBackground === 'false') {
          props.element.classList.add('text-red-500');
        }
      }
    }
    if (props.isDetailPrice) {
      props.element.innerHTML = '';
    }
    props.element.prepend(priceWrapper);

    // In Stock
    if (isInStock && injectBuyButton) {
      const buttonElement = props.element.querySelector('.add-to-cart-button[data-product-id]');
      props.element.classList.add('cursor-pointer');
      return (
        createPortal(
          <>
            <AddToCartButton
              element={buttonElement}
              productUrl={ buttonElement.dataset.productUrl }
              isDetailButton={false}
              wrapperClickable={true}
              onCartPage={false}
              variantId={props.variantId}
            />
          </>,
          props.element
        )
      );
    }

    // Not in stock
    if (!isInStock) {
      let renderElement = props.element;
      if (props.footerNotify) {
        renderElement = props.addToCartButtonWrapper;
      }
      if (relatedAddToCartButtons) {
        relatedAddToCartButtons.forEach((button) => {
          button.remove();
        });
      }
      if (relatedQuantitySelector) {
        relatedQuantitySelector.forEach((selector) => {
          selector.remove();
        });
      }
      if (inStockElement) {
        inStockElement.innerHTML = window.shopTranslations.outOfStock;
        inStockElement.classList.add('out-of-stock');
      }
      if (props.isDetailPrice) {

        const stickyNavButton = document.getElementById('sticky-nav-buy-button');
        if (stickyNavButton) {
          stickyNavButton.innerHTML = `${window.shopTranslations.notifyMeButton}<div class="w-4 h-4 ml-2.5"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16"><path fill="currentColor" d="M7 16a2 2 0 0 0 2-2H5a2 2 0 0 0 2 2Zm6.731-4.678C13.127 10.673 12 9.7 12 6.5a4.935 4.935 0 0 0-4-4.849V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v.651A4.935 4.935 0 0 0 2 6.5C2 9.7.873 10.673.269 11.322A.976.976 0 0 0 0 12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 .976.976 0 0 0-.269-.678Z" data-name="Path 1281"/></svg></div>`
        }
        props.element.parentElement.classList.add('out-of-stock');
      } else {
        if (props.showBackground === 'true') {
          props.element.classList.add('!bg-[#9EB5C1]');
        }
        if (props.showBackground === 'false') {
          props.element.parentElement.classList.add('out-of-stock');
        }
      }
      return (
        createPortal(
          <>
            {
              !props.iconOnly &&
              <div className={`${!props.footerNotify ? 'mt-1.25' : ''}`}>
                <OutOfStockButton
                  variantId={props.variantId}
                  iconOnly={props.iconOnly}
                  class={props.notifyMeClass}
                />
              </div>
            }
            {
              props.iconOnly &&
              <OutOfStockButton
                variantId={props.variantId}
                iconOnly={props.iconOnly}
                class={props.notifyMeClass}
              />
            }
          </>,
          renderElement
        )
      );
    }

  }

  return <></>;
};

export default ShowPrice;
