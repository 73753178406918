import { gql } from "@apollo/client";
import { LINEITEM_CHECKOUT_FRAGMENT, PRICE_FRAGMENT, CHECKOUT_FRAGMENT } from "./fragments";

export const GET_CHECKOUT_BY_ID = gql`
  ${LINEITEM_CHECKOUT_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${CHECKOUT_FRAGMENT}
  query GetCheckoutById($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ... CheckoutFragment
      }
    }
  }
`;
