import React from 'react';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import Progressbar from '../Progressbar';
import CheckMark from '../icons/CheckMark';

const FreeShipping = (props) => {

  const {
    percentage,
    amountToSpend,
    currencyCode
  } = props;

  const toSpend = CurrencyHelper.GetFormattedPrice({
    amount: amountToSpend,
    currencyCode
  });

  const message = percentage === 100 ? window.shopTranslations.cartFreeShippingBanner : window.shopTranslations.cartFreeShippingBannerSpend.replace('{amount}', toSpend);

  const iconColor = percentage === 100 ? 'text-green-500' : 'text-gray-200';

  const icon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.125 33">
        <g data-name="Group 3349">
          <g data-name="Group 3346">
            <path fill="currentColor"
                  d="M32.53 20.625a1.547 1.547 0 0 0 1.508-1.2l3.048-13.41a1.547 1.547 0 0 0-1.508-1.89H10.261L9.67 1.237A1.547 1.547 0 0 0 8.155 0H1.547A1.547 1.547 0 0 0 0 1.547v1.028a1.547 1.547 0 0 0 1.547 1.55h4.5l4.532 22.135a3.607 3.607 0 0 0-1.808 3.325 3.62 3.62 0 0 0 3.429 3.411 3.609 3.609 0 0 0 2.7-6.184h13.512a3.6 3.6 0 0 0-1.083 2.678 3.624 3.624 0 0 0 3.555 3.509 3.61 3.61 0 0 0 1.627-6.858l.356-1.566a1.547 1.547 0 0 0-1.508-1.89h-17.3l-.423-2.06Z"
                  data-name="Path 1132" />
          </g>
          {
            percentage === 100 ?
              <g data-name="Group 3347">
                <path fill="#fff"
                      d="M21.4 16.707a.5.5 0 0 0 .707 0l5.75-5.75a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0l-4.693 4.689-2.189-2.189a.5.5 0 0 0-.707 0l-.707.707a.5.5 0 0 0 0 .707Z"
                      data-name="Path 1131" />
              </g>
              :
              <path fill="#fff"
                    d="M21.358 16.5v-2.037h2.092V16.5Zm-2.577-2.037V16.5h-2.092v-2.037Zm9.331 0V16.5H26.02v-2.037Z"
                    data-name="Path 1134" />
          }
        </g>
      </svg>
    );
  };

  return (
    <>
      <div className="flex items-center flex-wrap justify-between">
        <div className="flex items-center flex-wrap">
          {percentage === 100 &&
            <div className="w-4 h-4 mr-2 text-green-500">
              <CheckMark/>
            </div>
          }
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </div>
        <div className={`w-[23px] h-[21px] md:hidden transition-colors duration-500 ${iconColor}`}>
          {icon()}
        </div>
      </div>
      <div className="flex justify-between items-end">
        <Progressbar percentage={percentage} />
        <div
          className={`w-[37px] h-[33px] hidden md:block ml-12 transition-colors duration-500 ${iconColor}`}>
          {icon()}
        </div>
      </div>
    </>
  );
};

export default FreeShipping;
