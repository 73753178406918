import React from 'react';
import { createPortal } from 'react-dom';
import OutOfStockForm from './OutOfStockForm';
import CustomPopup from '../CustomPopup';

const OutOfStockPopup = (props) => {
  const popupWrapper = document.getElementById('klaviyo-popup-wrapper');
  return(
    createPortal(
      <CustomPopup
        onClose={props.onClose}
        show={props.show}
      >
        <button className="shopify-cart-close" onClick={(e) => props.onClose(false)}>
          <svg width="20px" height="20px" viewBox="0 0 30 30">
            <g
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <path d="M7.5 22.5L22.5 7.5M7.5 7.5l15 15"></path>
            </g>
          </svg>
        </button>
        <div className="bg-green-lightest p-4 md:p-8">
          <h2
            className={'font-bold text-4xl text-green-600'}>{window.shopTranslations.notifyMePopupHeader}</h2>
          <div className="mt-6">
            <OutOfStockForm
              variantId={props.variantId}
            ></OutOfStockForm>
          </div>
        </div>
      </CustomPopup>,
      popupWrapper
    )
  )
}

export default OutOfStockPopup
