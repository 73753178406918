import React, { useEffect, useState } from "react";
import axios from "axios";
import { GET_RELATED } from '../../queries/get-related';
import RelatedProduct from "./RelatedProduct";
import LoadingSpinner from '../loadingSpinner';

const RelatedProducts = (props) => {
  const [state, setState] = useState({products: [], title: ''});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.post(
      '/craft-api',
      {
        query: GET_RELATED,
        variables: {
          id: props.id,
          siteId: window.currentSiteId,
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).then(res => {
      if (res && res.data && res.data.data && res.data.data.entries && res.data.data.entries.length > 0) {
        setState(prevState => ({
          ...prevState,
          title: res.data.data.entries[0].relatedProductsTitle,
          products: res.data.data.entries[0].shopifyRelatedProducts
        }));
      }
      setIsLoading(false);
    })
  }, []);

  const freeProducts = props.freeProducts;
  const isVariant = props.isVariant;

  if (isLoading) {
    return <div className="px-6 mt-3 flex items-center justify-center"><LoadingSpinner/></div>;
  }


  if (isVariant) {
    return <div className="shopify-related-products px-6 mt-3">
      <h4 className="font-bold">{window.generalRecommendedProductsTitle}</h4>
      <div className="grid md:grid-cols-3 gap-x-5 gap-y-5 mt-6">
        {window.generalRecommendedProducts.map(product =>
          <RelatedProduct
            key={product.id}
            title={product.title}
            width={window.generalRecommendedProducts.length}
            label={product.label}
            productId={product.productId}
            variantId={product.variantId}
            craftId={product.id}
            parentTitle={props.title}
            url={product.url}
            slug={product.slug}
            imageUrl={product.imageUrl}
            imageTitle={product.imageTitle}
            intro={product.intro}
            freeProducts={ freeProducts }
            routine={product.routine}
          />)
        }
      </div>
    </div>
  }

  if (window.enableOverwrite && window.toOverwriteProducts.includes(props.id)) {
    return <div className="shopify-related-products px-6 mt-3">
      <h4 className="font-bold">{window.overwriteTitle}</h4>
      <div className="grid md:grid-cols-3 gap-x-5 gap-y-5 mt-6">
        {window.overwriteProducts.map(product =>
          <RelatedProduct
            key={product.id}
            title={product.title}
            width={window.overwriteProducts.length}
            label={product.label}
            productId={product.id}
            variantId={product.variantId}
            craftId={product.id}
            parentTitle={props.parentTitle}
            url={product.url}
            slug={product.slug}
            imageUrl={product.imageUrl}
            imageTitle={product.imageTitle}
            intro={product.intro}
            freeProducts={ freeProducts }
            routine={product.routine}
          />)
        }
      </div>
    </div>
  }

  return (state.products.length > 0 &&
      <div className="shopify-related-products px-6 mt-3">
        <h4 className="font-bold">{state.title}</h4>
        <div className="grid md:grid-cols-3 gap-x-5 gap-y-5 mt-6">
          {state.products.map(product =>
            <RelatedProduct
              key={product.id}
              title={product.title}
              width={state.products.length}
              label={product.label}
              productId={product.shopifyProductId}
              variantId={product.shopifyStorefrontVariantId}
              craftId={product.id}
              parentTitle={props.parentTitle}
              url={product.url}
              slug={product.slug}
              imageUrl={product.productImages[0].url}
              imageTitle={product.productImages[0].title}
              intro={product.intro}
              freeProducts={ freeProducts }
              routine={product.routine}
            />)
          }
        </div>
      </div>)
}

export default RelatedProducts;
