import React from 'react';
import ReactDOM from 'react-dom';
import Cart from './ui/Cart';
import CartOverview from './ui/CartOverview';
import Message from './components/Message';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import store from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const localStorageKey = `${window.languageLocale}-shopify-remescar-cart`;

const domain = window.shopifyStorefrontDomain;
const token = window.shopifyStorefrontAccessToken;

const httpLink = createHttpLink({
  uri: domain,
  headers: {
    'X-Shopify-Storefront-Access-Token': token,
    'Accept': "application/json"
  }
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

const miniCart = document.querySelector('.shopify-mini-cart');
let persistor = persistStore(store);

const query = new URLSearchParams(window.location.search);
const discountCodeLocalStorageKey = `${localStorageKey}-discountCode`;
let discountCodeFromUrl = query.get('discount');
if (discountCodeFromUrl) {
  const discountCodeLocalStorage = localStorage.getItem(discountCodeLocalStorageKey);
  const decodedMessage = decodeURI(discountCodeFromUrl);
  const messageWrapper = document.getElementById('js-shopify-messages');
  if (!discountCodeLocalStorage) {
    localStorage.setItem(discountCodeLocalStorageKey, decodedMessage);
    const message = window.shopTranslations.discountAddedBanner.replace('{discountCode}', decodedMessage)
    const messageNode = document.createElement('div');
    messageNode.className = 'p-2 bg-red-500 text-center text-white';
    messageNode.innerHTML = message;
    messageWrapper.appendChild(messageNode);

    const url = new URL(window.location);
    url.searchParams.delete('discount');
    history.replaceState(null, null, url);
  }
}

if (miniCart) {
  const favoriteCountElements = document.querySelectorAll('.wishlist-count');
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Cart
          client={client}
          localStorageKey={localStorageKey}
          favoriteCountElements={favoriteCountElements}
        />
      </PersistGate>
    </Provider>,
    miniCart
  );
}


const cartElement = document.querySelector('#shopify-cart');
if (cartElement) {
  const priceRangeFreeProductsArray = [];
  const numberOfItemsFreeProductsArray = [];
  const discountProductsArray = [];

  if (cartElement.dataset.priceRangeFreeProducts) {
    cartElement.dataset.priceRangeFreeProducts.split(',').forEach((freeProduct) => {
      const segments = freeProduct.split('/');
      priceRangeFreeProductsArray.push({
        min: +segments[0],
        id: +segments[1]
      })
    })
  }

  if (cartElement.dataset.numberOfItemsFreeProducts) {
    cartElement.dataset.numberOfItemsFreeProducts.split(',').forEach((freeProduct) => {
      const segments = freeProduct.split('/');
      numberOfItemsFreeProductsArray.push({
        min: +segments[0],
        id: +segments[1]
      })
    })
  }

  if (cartElement.dataset.discountProducts) {
    cartElement.dataset.discountProducts.split(',').forEach((discountProduct) => {
      const segments = discountProduct.split('/');
      discountProductsArray.push({
        code: segments[0],
        id: +segments[1]
      })
    })
  }
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <CartOverview
            element={cartElement}
            client={client}
            freeProducts={ priceRangeFreeProductsArray }
            cartItemsFreeProducts={ numberOfItemsFreeProductsArray }
            discountProducts={ discountProductsArray }
            discountCodeLocalStorageKey={discountCodeLocalStorageKey}
          ></CartOverview>
        </ApolloProvider>
      </PersistGate>
    </Provider>,
    cartElement
  );
}
