import React, { useEffect, useState } from 'react'
import { GET_SHOPICONS } from '../../queries/get-payment-icons'
import axios from 'axios'

const PaymentOptions = (props) => {
  const [icons, setIcons] = useState([])
  useEffect(() => {
    axios
      .post(
        '/craft-api',
        {
          query: GET_SHOPICONS,
          variables: {
            siteId: window.currentSiteId,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.globalSet &&
          res.data.data.globalSet.shopifyIcons &&
          res.data.data.globalSet.shopifyIcons.length > 0
        ) {
          setIcons(res.data.data.globalSet.shopifyIcons)
        }
      })
  }, [])

  return (
    <>
      <div> {window.shopTranslations.paymentOptionsLabel} </div>
      <div className="flex flex-wrap items-center mt-1.5">
        {icons &&
          icons.map((icon, key) => {
            return (
              <div key={key} className="mr-2 mb-2 w-9">
                <img src={icon.url} title={icon.title}/>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default PaymentOptions
