import React, { Component } from 'react';
import { Provider } from 'react-redux';
import CustomPopup from './CustomPopup';
import { createPortal } from 'react-dom';
import store from '../store/store';
import MarketingMessages from './MarketingMessages/MarketingMessages';
import RelatedProducts from './RelatedProducts/RelatedProducts';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import CheckMark from './icons/CheckMark';
import PriceHelper from '../helpers/PriceHelper';

class ProductPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupVisible: false,
      key: 0,
      totalPrice: props.totalPrice,
      totalItems: props.totalItems,
      productIdCraft: props.productIdCraft,
      freeProducts: props.freeProducts,
      isVariant: props.isVariant,
      message: props.message
    };
    this.disableScroll = this.disableScroll.bind(this);
    this.enableScroll = this.enableScroll.bind(this);
    this.popupCloseHandler = this.popupCloseHandler.bind(this);
    this.httpLink = createHttpLink({
      uri: window.shopifyStorefrontDomain,
      headers: {
        'X-Shopify-Storefront-Access-Token': window.shopifyStorefrontAccessToken,
      },
    });
    this.client = new ApolloClient({
      link: this.httpLink,
      cache: new InMemoryCache(),
    });
  }

  componentDidMount() {
    this.setVisibility(true);
    this.disableScroll();
  }

  disableScroll() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }

  enableScroll() {
    document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    document.getElementsByTagName('html')[0].style.removeProperty('overflow');
  }

  setVisibility(e) {
    this.setState({ popupVisible: e });
    if (e) {
      this.disableScroll()
    } else {
      this.enableScroll()
    }
  }

  popupCloseHandler(e) {
    this.setVisibility(e);
  }

  render() {
    const { title } = this.props.product;

    const popupWrapper = document.getElementById('product-popup-wrapper');
    let freeShippingPercentage;
    let freeShippingToSpend;
    if (Number(this.state.totalPrice.amount) >= Number(window.freeShippingFrom)) {
      freeShippingPercentage = 100;
      freeShippingToSpend = 0;
    } else {
      freeShippingPercentage = PriceHelper.PercentageHelper(this.state.totalPrice.amount, window.freeShippingFrom);
      freeShippingToSpend = Number(window.freeShippingFrom) - this.state.totalPrice.amount;
    }

    return (
      createPortal(
        <CustomPopup
          onClose={this.popupCloseHandler}
          show={this.state.popupVisible}
        >
          <button className="shopify-cart-close" onClick={(e) => this.setVisibility(false)}>
            <svg width="20px" height="20px" viewBox="0 0 30 30">
              <g
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
              >
                <path d="M7.5 22.5L22.5 7.5M7.5 7.5l15 15"></path>
              </g>
            </svg>
          </button>
          <div className="bg-green-lightest p-4 md:p-8 md:flex justify-between items-start">
            <div>
              <h3 className={"flex items-center"}>
                <div className="w-4 h-4 mr-2 text-green-500">
                  <CheckMark/>
                </div>
                <div>
                  <span className="font-bold">{title}</span> {window.shopTranslations.addedToBasket}
                </div>
              </h3>
            </div>
            <div className="text-right ml-4">
              <a
                href={window.cartUrl}
                className="button whitespace-nowrap mt-4 sm:mt-0 button--green"
              >
                {window.shopTranslations.alertCartButton}
                <div className={"ml-3"}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.353"><path d="M6.697 1.026a.839.839 0 0 0 .014 1.206l4.237 4.038H.848a.842.842 0 0 0-.844.844v1.125a.842.842 0 0 0 .844.844h10.1l-4.237 4.035a.845.845 0 0 0-.014 1.208l.78.78a.84.84 0 0 0 1.192 0l6.835-6.836a.84.84 0 0 0 0-1.192L8.669.248a.84.84 0 0 0-1.192 0Z" fill="currentColor"/></svg>
                </div>
              </a>
              <p className="mb-0 mt-6">
                <button
                  onClick={(e) => this.setVisibility(false)}
                  className="no-underline hover:underline text-green-dark"
                >
                  { window.shopTranslations.alertContinue }
                </button>
              </p>
            </div>
          </div>
          <div className="px-6 sm:flex sm:flex-wrap mt-3 -mx-3">
            <Provider store={store}>
              <MarketingMessages
                total={this.state.totalPrice.amount}
                totalItems={this.state.totalItems}
                showShipping={true}
                onlyNotCompleted={false}
                class={'p-3 sm:w-1/2'}
                innerClass={'p-4'}
                freeShippingPercentage={freeShippingPercentage}
                freeShippingToSpend={freeShippingToSpend}
                currencyCode={this.props.currencyCode}
              ></MarketingMessages>
            </Provider>
          </div>
          <ApolloProvider client={ this.client }>
            <RelatedProducts
              id={this.state.productIdCraft}
              parentTitle={this.props.product.title}
              freeProducts={this.state.freeProducts}
              isVariant={this.state.isVariant}
            />
          </ApolloProvider>
          <div className="bg-green-lightest p-4 md:p-8 flex-col items-center text-center mt-6">
            <a
              href={window.cartUrl}
              className="button mt-4 sm:mt-0 button--green"
            >
              {window.shopTranslations.alertCartButton}
              <div className={"ml-3"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.353"><path d="M6.697 1.026a.839.839 0 0 0 .014 1.206l4.237 4.038H.848a.842.842 0 0 0-.844.844v1.125a.842.842 0 0 0 .844.844h10.1l-4.237 4.035a.845.845 0 0 0-.014 1.208l.78.78a.84.84 0 0 0 1.192 0l6.835-6.836a.84.84 0 0 0 0-1.192L8.669.248a.84.84 0 0 0-1.192 0Z" fill="currentColor"/></svg>
              </div>
            </a>
          </div>
        </CustomPopup>,
        popupWrapper
      )
    );
  }
}

export default ProductPopup;
