import React, { useEffect, useState } from 'react';

const CustomPopup = (props) => {

  useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27){
        if (props.show) {
          props.onClose(false)
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)

  }, [props.show]);

  return (
    <div
      style={{
        visibility: props.show ? 'visible' : 'hidden',
        opacity: props.show ? '1' : '0'
      }}
      onClick={(e) => props.onClose(false)}
      className={'product-popup__overlay'}
    >
      <div
        className={`product-popup__wrapper bg-white relative`}
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default CustomPopup;
