import { gql } from "@apollo/client";
import { PRICE_FRAGMENT } from "./fragments";

export const IS_IN_STOCK_QUERY = gql`
  ${PRICE_FRAGMENT}
  query GetVariantById($id: ID!) {
    node(id: $id) {
      ... on ProductVariant {
        quantityAvailable
        availableForSale
        currentlyNotInStock
        image {
          altText
          id
          transformedSrc
        }
        priceV2 {
          ...PriceFragment
        }
        compareAtPriceV2 {
          ...PriceFragment
        }
      }
    }
  }
`;
