import ShopifyHelper from "./ShopifyHelper";

export default class LineItemsHelper {
  static checkIfLineItemExists = (lineItems, lineItemId) => {
    if (!lineItems.edges || !lineItems) {
      return false;
    }
    return !!lineItems.edges.find(item => item.node.variant.id === ShopifyHelper.getStorefrontId(lineItemId));
  }

  static getNodeIdByVariantId = (lineItems, variantId) => {
    const found = lineItems.edges.find(item => item.node.variant.id === ShopifyHelper.getStorefrontId(variantId));

    if (found) {
      return found.node.id;
    }

    return undefined;
  }
  // Return number of items in checkout without free products.
  static getNumberOfItemsInCheckout = (lineItems) => {
    let totalQuantity = 0;
    lineItems.edges.map((item) => {
      if (item.node.variant.priceV2.amount !== "0.0") {
        totalQuantity += item.node.quantity;
      }
    });
    return totalQuantity;
  }
}
