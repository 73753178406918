import React, { Component } from 'react';
import ShopifyHelper from '../helpers/ShopifyHelper';
import ProductPopup from './ProductPopup';
import CheckoutHandler from './CheckoutHandler';
import VariantContext from './VariantContext';

class AddToCartButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variantId: props.variantId,
      checkoutHandler: null,
      quantityWrapper: null,
      quantity: 1,
      popup: null,
      totalPrice: null,
      loading: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.setQuantityWrapper = this.setQuantityWrapper.bind(this);
    this.decreaseQuantity = this.decreaseQuantity.bind(this);
    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.getQuantity = this.getQuantity.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.setPopup = this.setPopup.bind(this);
  }


  static contextType = VariantContext;

  componentDidMount() {
    this.setQuantityWrapper();
    if (this.props.wrapperClickable) {
      const wrapper = this.props.element.closest('.price-of-variant[data-product-id]');
      if (wrapper) {
        wrapper.addEventListener("click", this.handleClick);
      }
    } else {
      this.props.element.addEventListener("click", this.handleClick);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contextVariant } = this.context;
    if (this.props.isDetailButton && contextVariant.node) {
      const newVariantId = ShopifyHelper.getShopifyId(contextVariant.node.id);
      if (this.state.variantId !== newVariantId) {
        this.setState((prevState) => ({variantId: newVariantId}));
      }
    }
  }

  setQuantityWrapper() {
    const quantityWrapper = document.querySelector(
      ".add-to-cart-quantity[data-variant-id='" + this.props.variantId + "']"
    );

    if (quantityWrapper) {
      this.setState({ quantityWrapper: quantityWrapper });
      quantityWrapper
        .querySelector(".quantity-decrease")
        .addEventListener("click", this.decreaseQuantity);

      quantityWrapper
        .querySelector(".quantity-increase")
        .addEventListener("click", this.increaseQuantity);

      this.changeQuantity();
    }
  }

  changeQuantity() {
    const { quantity } = this.state;

    const x = document.querySelector(
      ".add-to-cart-quantity[data-variant-id='" +
        this.props.variantId +
        "'] input"
    );

    x.value = quantity;
  }

  decreaseQuantity() {
    const { quantity } = this.state;

    if (quantity > 1) {
      this.setState({ quantity: quantity - 1 });
      this.changeQuantity();
    }
  }

  increaseQuantity() {
    const { quantity } = this.state;

    if (quantity < 5) {
      this.setState({ quantity: quantity + 1 });
      this.changeQuantity();
    }
  }

  getQuantity() {
    const { quantity } = this.state;

    return quantity;
  }

  handleClick() {
    const { b64id } = this.state;

    // Reset checkout handler
    this.setState({checkoutHandler: null})

    // apply checkoutHandler using graphql
    this.setState({
      checkoutHandler: (
        <CheckoutHandler
          variantId={this.state.variantId}
          getQuantity={this.getQuantity}
          setPopup={this.setPopup}
          onCartPage={this.props.onCartPage}
          productUrl={this.props.productUrl}
          isRelatedButton={this.props.isRelatedButton}
          onProductDetailPage={this.props.onProductDetailPage}
        ></CheckoutHandler>
      ),
    });
  }

  setPopup(options) {
    const { element } = this.props;
    const craftId = element.getAttribute("data-craft-id");
    this.setState({popup: null});
    if (craftId !== null) {
      this.setState({
        popup: (
          <ProductPopup
            productIdCraft={craftId}
            product={options.product}
            totalPrice={options.totalPrice}
            totalItems={options.totalItems}
            currencyCode={options.currencyCode}
          ></ProductPopup>
        ),
      });
    } else {
      console.log("No craft-id found in element");
    }
  }

  render() {
    const { checkoutHandler, popup } = this.state;

    return (
      <>
        {checkoutHandler}
        {popup}
      </>
    );
  }
}

export default AddToCartButton;
