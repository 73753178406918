import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import {
  updateActiveDiscountCode,
  updateId,
  updateLineItems,
  updateMiniCart,
  updateTotalPrice,
  updateWebUrl
} from '../store/shopifySlice';
import CurrencyHelper from '../helpers/CurrencyHelper';
import ShopifyHelper from '../helpers/ShopifyHelper';
import LineItemsHelper from '../helpers/LineItemsHelper';
import PriceHelper from '../helpers/PriceHelper';
import { REMOVE_LINE_ITEM } from '../queries/remove-line-item';
import { DISCOUNT_CODE_APPLY } from '../queries/discount-code-apply';
import { DISCOUNT_CODE_REMOVE } from '../queries/discount-code-remove';
import { GET_CHECKOUT_BY_ID } from '../queries/get-checkout-by-id';
import { ADD_TO_CART } from '../queries/add-to-cart';
import { CREATE_CHECKOUT } from '../queries/create-checkout';
import Message from '../components/Message';
import EmptyCart from '../components/Cart/EmptyCart';
import ProductInCart from '../components/Cart/ProductInCart';
import LoadingSpinner from '../components/loadingSpinner';
import CartTotal from '../components/Cart/CartTotal';
import { updateLoading, resetState } from '../store/shopifySlice';
import FreeShipping from '../components/Cart/FreeShipping';
import MarketingMessages from '../components/MarketingMessages/MarketingMessages';
import OrderButton from '../components/Cart/OrderButton';

const CartOverview = (props) => {
  const currencyCode = useSelector((state) => state.shopify.currencyCode);
  const checkoutId = useSelector((state) => state.shopify.id);
  const lineItemIds = useSelector((state) => state.shopify.lineItemIds);
  const shopifyLoading = useSelector((state) => state.shopify.loading);
  const activeDiscountCode = useSelector((state) => state.shopify.activeDiscountCode);
  const freeProducts = props.freeProducts;
  const cartItemsFreeProducts = props.cartItemsFreeProducts;
  const discountProducts = props.discountProducts;
  const [addToCart] = useMutation(ADD_TO_CART);
  const [createCheckout] = useMutation(CREATE_CHECKOUT);

  const [discountErrors, setDiscountErrors] = useState([]);

  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState(activeDiscountCode);

  const {
    loading,
    error,
    data,
    refetch
  } = useQuery(GET_CHECKOUT_BY_ID, {
    variables: { checkoutId: checkoutId },
    enabled: false,
    skip: checkoutId === 0,
    onCompleted: () => {
      if (data && data.node && data.node.lineItems && data.node.lineItems.edges && data.node.lineItems.edges.length > 0) {
        dispatch(updateLineItems(data.node.lineItems));
        window.cartItemsTitles = [];
        data.node.lineItems.edges.map(({ node }) => {
          window.cartItemsTitles.push(node.title);
        });

        checkPromo(data.node.totalPriceV2.amount, data.node.lineItems);

        const checkoutPromo = getCheckoutPromotions(data.node.discountApplications);
        if (checkoutPromo.length) {
          dispatch(updateActiveDiscountCode(checkoutPromo[0].title));
        }

        checkIfPromoCodeInLocalStorage();

      }
    }
  });

  useEffect(() => {
    if (data) {
      if (!data.node) {
        dispatch(resetState());
      }
      if (data.node && data.node.completedAt && data.node.createdAt) {
        const completed = new Date(data.node.completedAt);
        const created = new Date(data.node.createdAt);

        if (created < completed) {
          // reset the checkout id if completed
          dispatch(resetState());
        }
      }
    }
  });

  const [checkoutLineItemsRemove] = useMutation(REMOVE_LINE_ITEM, {
    onCompleted() {
      if (data && data.node && data.node.lineItems && data.node.lineItems.edges && data.node.lineItems.edges.length > 0) {
        dispatch(updateTotalPrice(data.node.totalPriceV2));
        dispatch(updateLineItems(data.node.lineItems));

        checkPromo(data.node.totalPriceV2.amount, data.node.lineItems);
      }
    }
  });

  const [checkoutDiscountCodeApplyV2] = useMutation(DISCOUNT_CODE_APPLY, {
    onCompleted({ checkoutDiscountCodeApplyV2: { checkoutUserErrors } }) {
      setDiscountErrors([]);
      setDiscountCode('');
      if (checkoutUserErrors.length === 0) {
        const discountProduct = discountProducts.find(discountProduct => discountProduct.code === discountCode);

        if (discountProduct) {
          sendItemToShopify(checkoutId, discountProduct.id, () => {
            dispatch(updateMiniCart(true));
          });
        }

        dispatch(updateActiveDiscountCode(discountCode));

        localStorage.removeItem(props.discountCodeLocalStorageKey);
        const url = new URL(window.location);
        url.searchParams.delete('discount');
        history.replaceState(null, null, url);
        refetch();
      } else {
        localStorage.removeItem(props.discountCodeLocalStorageKey);
        setDiscountErrors(checkoutUserErrors);
      }
    }
  });

  const [checkoutDiscountCodeRemove] = useMutation(DISCOUNT_CODE_REMOVE, {
    onCompleted({ checkoutDiscountCodeRemove: { checkoutUserErrors } }) {
      if (checkoutUserErrors.length === 0) {
        dispatch(updateActiveDiscountCode(''));
        localStorage.removeItem(props.discountCodeLocalStorageKey);
        const url = new URL(window.location);
        url.searchParams.delete('discount');
        history.replaceState(null, null, url);

        const discountProduct = discountProducts.find(discountProduct => discountProduct.code === discountCode);
        if (discountProduct) {
          if (LineItemsHelper.checkIfLineItemExists(data.node.lineItems, discountProduct.id)) {
            const discountProductId = LineItemsHelper.getNodeIdByVariantId(data.node.lineItems, discountProduct.id);

            const removeOptions = {
              variables: {
                checkoutId: checkoutId,
                lineItemIds: [discountProductId]
              }
            };
            checkoutLineItemsRemove(removeOptions);
          }
        }

        refetch();
      } else {
        setDiscountErrors(checkoutUserErrors);
      }
    }
  });

  if (loading) {
    return <div className="relative min-h-6">
      <div
        className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 px-6 mt-3 flex items-center justify-center">
        <LoadingSpinner /></div>
    </div>;
  }

  function sendItemToShopify(checkoutId, productId, callBack) {
    const base64ProductId = ShopifyHelper.getStorefrontId(productId);

    if (checkoutId) {
      const options = {
        variables: {
          lineItems: [{
            variantId: base64ProductId,
            quantity: 1
          }],
          checkoutId: checkoutId
        }
      };

      addToCart(options)
        .then(res => {
          const {
            data: {
              checkoutLineItemsAdd: {
                checkout: {
                  id,
                  totalPriceV2,
                  lineItems
                }
              }
            }
          } = res;
          callBack(totalPriceV2.amount, id, lineItems);
        });
    } else {
      const options = {
        variables: {
          lineItems: [{
            variantId: base64ProductId,
            quantity: 1
          }]
        }
      };

      createCheckout(options)
        .then(res => {
          const {
            data: {
              checkoutCreate: {
                checkout: {
                  id,
                  webUrl,
                  totalPriceV2,
                  lineItems
                }
              }
            }
          } = res;

          dispatch(updateId(id));
          dispatch(updateWebUrl(webUrl));

          callBack(totalPriceV2.amount, id, lineItems);
        });
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: currencyCode,
        add: {
          actionField: {
            list: 'Shopping cart'
          },
          products: [{
            variantId: base64ProductId,
            quantity: 1
          }]
        }
      }
    });
  }

  const getCheckoutPromotions = (checkoutDiscounts, lineItemsTotal) => {
    if (checkoutDiscounts && checkoutDiscounts.edges) {
      return checkoutDiscounts.edges
        .filter(({ node }) => node.targetSelection === 'ALL')
        .map(({ node }) => {
          return {
            amount: node.value.percentage ? +lineItemsTotal / 100 * +node.value.percentage : node.value.amount,
            percentage: node.value.percentage ?? false,
            title: node.code || node.title
          };
        });
    }

    return [];
  };

  const getProductPromotions = (lineItems) => {
    let allPromotionsAmount = 0;
    if (lineItems && lineItems.edges) {
      lineItems.edges
        .map(({ node }) => {
          if (node.discountAllocations.length !== 0) {
            node.discountAllocations
              .map((discount) => {
                if (discount.discountApplication.targetSelection === 'EXPLICIT' || discount.discountApplication.targetSelection === 'ENTITLED') {
                  allPromotionsAmount += Number(discount.allocatedAmount.amount);
                }
              });
          }
        });
    }

    return allPromotionsAmount;
  };

  const getPromotions = (node) => {
    let totalPromotions = 0;
    let currencyCode;
    let title;

    const validDiscounts = node.discountAllocations.find((discountAllocation) => {
      return discountAllocation.discountApplication.targetSelection === 'ENTITLED' || 'EXPLICIT';
    });

    if (node.discountAllocations && node.discountAllocations.length) {
      currencyCode = node.discountAllocations[0].allocatedAmount.currencyCode;
    }

    if (validDiscounts) {
      currencyCode = validDiscounts.allocatedAmount.currencyCode;
      totalPromotions = validDiscounts.allocatedAmount.amount;
      title = validDiscounts.discountApplication.code || validDiscounts.discountApplication.title;
    }

    return {
      amount: totalPromotions,
      currencyCode: currencyCode,
      title: title
    };
  };

  const handleDelete = (e, itemId) => {
    e.preventDefault();

    const options = {
      variables: {
        checkoutId: checkoutId,
        lineItemIds: [itemId]
      }
    };
    dispatch(updateLoading(true));
    checkoutLineItemsRemove(options)
      .then(() => {
        dispatch(updateLoading(false));
      });
  };

  const openCheckout = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'clickCheckoutCart'
    });
  };

  const handleAddDiscountCode = (event = false, code = null) => {
    const options = {
      variables: {
        discountCode: code ?? discountCode,
        checkoutId: checkoutId
      }
    };

    dispatch(updateLoading(true));
    checkoutDiscountCodeApplyV2(options)
      .then(() => {
        dispatch(updateLoading(false));
      });
    if (event) {
      event.preventDefault();
    }
  };

  const removeDiscountCode = (event) => {
    dispatch(updateLoading(true));
    if (event) {
      event.preventDefault();
    }

    const options = {
      variables: {
        checkoutId: checkoutId
      }
    };

    checkoutDiscountCodeRemove(options)
      .then(() => {
        dispatch(updateLoading(false));
      });
  };

  const checkIfPromoCodeInLocalStorage = () => {
    const discountCode = localStorage.getItem(props.discountCodeLocalStorageKey);
    if (discountCode) {
      handleAddDiscountCode(false, discountCode);
    }
  };

  const checkPromo = (totalAmount, lineItems) => {
    const totalItems = LineItemsHelper.getNumberOfItemsInCheckout(lineItems);
    const addFreeItems = [];
    let removeFreeItems = [];
    for (const freeProduct of freeProducts) {
      if (freeProduct.min <= +totalAmount) {
        if (!LineItemsHelper.checkIfLineItemExists(lineItems, freeProduct.id)) {
          addFreeItems.push({
            variantId: ShopifyHelper.getStorefrontId(freeProduct.id),
            quantity: 1
          });
        }
      }
      if (freeProduct.min > +totalAmount) {
        if (LineItemsHelper.checkIfLineItemExists(lineItems, freeProduct.id)) {
          const freeNodeId = LineItemsHelper.getNodeIdByVariantId(lineItems, freeProduct.id);
          removeFreeItems.push(freeNodeId);
        }
      }
    }

    for (const freeProduct of cartItemsFreeProducts) {
      if (freeProduct.min <= totalItems) {
        if (!LineItemsHelper.checkIfLineItemExists(lineItems, freeProduct.id)) {
          addFreeItems.push({
            variantId: ShopifyHelper.getStorefrontId(freeProduct.id),
            quantity: 1
          });
        }
      }

      if (freeProduct.min > totalItems) {
        if (LineItemsHelper.checkIfLineItemExists(lineItems, freeProduct.id)) {
          const freeNodeId = LineItemsHelper.getNodeIdByVariantId(lineItems, freeProduct.id);
          removeFreeItems.push(freeNodeId);
        }
      }
    }

    if (addFreeItems.length) {
      const addOptions = {
        variables: {
          lineItems: addFreeItems,
          checkoutId
        }
      };
      addToCart(addOptions);
    }


    if (totalItems === 0) {
      removeFreeItems = lineItemIds;
    }

    if (removeFreeItems.length) {
      const removeOptions = {
        variables: {
          checkoutId: checkoutId,
          lineItemIds: removeFreeItems
        }
      };
      checkoutLineItemsRemove(removeOptions);
    }

  };

  const getCompareAtPriceTotal = (lineItems) => {
    let compareAtPriceTotal = 0;
    lineItems.edges.map((item) => {
      if (item.node.variant.compareAtPriceV2 && Number(item.node.variant.priceV2.amount) !== 0.0) {
        const priceDiff = Math.abs(Number(item.node.variant.priceV2.amount) - Number(item.node.variant.compareAtPriceV2.amount));
        compareAtPriceTotal += priceDiff * item.node.quantity;
      }
    });
    return compareAtPriceTotal;
  };

  const roundNumber = (num, scale) => {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      const arr = ('' + num).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
    }
  };

  let subTotal = false;
  let total = false;
  let promotionsTotal = false;
  let calculatedShippingCost = CurrencyHelper.GetFormattedPrice({
    amount: window.shippingCost,
    currencyCode: currencyCode
  });
  let freeShippingPercentage = false;
  let freeShippingToSpend = false;

  if (data && data.node && data.node.lineItemsSubtotalPrice && data.node.totalPriceV2) {

    let subtotalAmount = data.node.lineItemsSubtotalPrice.amount;

    const productPromotionsAmount = getProductPromotions(data.node.lineItems, data.node.lineItemsSubtotalPrice.amount);
    let allPromotionsAmount;
    const checkoutPromotions = getCheckoutPromotions(data.node.discountApplications, data.node.lineItemsSubtotalPrice.amount);
    if (checkoutPromotions.length > 0) {
      const { amount } = checkoutPromotions[0];
      allPromotionsAmount = Number(productPromotionsAmount) + Number(amount);
      subtotalAmount -= Number(amount);
    } else {
      allPromotionsAmount = Number(productPromotionsAmount);
    }

    const compareAtPriceTotal = getCompareAtPriceTotal(data.node.lineItems);
    if (compareAtPriceTotal !== 0) {
      allPromotionsAmount += compareAtPriceTotal;
    }

    if (productPromotionsAmount !== 0) {
      subtotalAmount -= productPromotionsAmount;
    }

    total = CurrencyHelper.GetFormattedPrice({
      amount: Number(data.node.totalPriceV2.amount) + Number(window.shippingCost),
      currencyCode: currencyCode
    });

    if (allPromotionsAmount !== 0) {
      promotionsTotal = '- ' + CurrencyHelper.GetFormattedPrice({
        amount: allPromotionsAmount,
        currencyCode: currencyCode
      });
    }

    if (Number(data.node.totalPriceV2.amount) >= Number(window.freeShippingFrom)) {
      calculatedShippingCost = window.shopTranslations.cartFreeShipping;
      total = CurrencyHelper.GetFormattedPrice({
        amount: data.node.totalPriceV2.amount,
        currencyCode: currencyCode
      });
      freeShippingPercentage = 100;
      freeShippingToSpend = 0;
    } else {
      freeShippingPercentage = PriceHelper.PercentageHelper(data.node.totalPriceV2.amount, window.freeShippingFrom);
      freeShippingToSpend = Number(window.freeShippingFrom) - data.node.totalPriceV2.amount;
    }

    subTotal = CurrencyHelper.GetFormattedPrice({
      amount: roundNumber(subtotalAmount, 2),
      currencyCode: currencyCode
    });

  }

  return (
    <div className="relative">
      {(!!checkoutId && error) && <Message message={error.message} />}

      {shopifyLoading && <div
        className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-10 flex items-center justify-center">
        <LoadingSpinner /></div>}

      {(!checkoutId || data && data.node && data.node.lineItems && data.node.lineItems.edges && data.node.lineItems.edges.length === 0) &&
        <EmptyCart />
      }

      {data && data.node && data.node.lineItems && data.node.lineItems.edges && data.node.lineItems.edges.length !== 0 &&
        <div className="row">
          <div className="col md:w-2/3">
            <div className="md:hidden mb-10 md:mb-0">
              <OrderButton
                url={data.node.webUrl}
                label={window.shopTranslations.cartCheckoutLabelBottom}
                clickHandler={openCheckout}
              />
              <div className="flex justify-between mt-6">
                <div className="font-bold text-xl">{window.shopTranslations.cartSubtotal}</div>
                <div className="font-bold text-xl text-green-600">{subTotal}</div>
              </div>
            </div>
            {freeShippingPercentage &&
              <div className="bg-green-lightest p-6 rounded mt-10 md:mt-0">
                <FreeShipping
                  percentage={freeShippingPercentage}
                  amountToSpend={freeShippingToSpend}
                  currencyCode={currencyCode}
                />
              </div>
            }
            <div className="mt-6">
              {data.node.lineItems.edges.map(({ node }) => {
                const promotions = getPromotions(node);
                let subTotal = {
                  amount: node.variant.priceV2.amount * node.quantity,
                  currencyCode: node.variant.priceV2.currencyCode
                };
                let promotionSubTotal;
                if (node.variant.priceV2 !== 0.0) {
                  if (node.variant.compareAtPriceV2) {
                    promotionSubTotal = {
                      amount: node.variant.compareAtPriceV2.amount * node.quantity,
                      currencyCode: node.variant.compareAtPriceV2.currencyCode
                    };
                  }

                  if (!node.variant.compareAtPriceV2 && (promotions && promotions.amount > 0)) {
                    promotionSubTotal = {
                      amount: node.variant.priceV2.amount * node.quantity,
                      currencyCode: node.variant.priceV2.currencyCode
                    };
                  }
                }

                return (node.quantity > 0 &&
                  <ProductInCart
                    key={`product-in-cart-${node.id}`}
                    product={node}
                    subTotal={subTotal}
                    promotionSubTotal={promotionSubTotal}
                    promotions={promotions}
                    freeProducts={freeProducts}
                    cartItemsFreeProducts={cartItemsFreeProducts}
                    handleDelete={handleDelete}
                    checkPromo={checkPromo}
                    refetch={refetch}
                  ></ProductInCart>
                );
              })}
            </div>
            <div className="mt-6">
              <MarketingMessages
                total={data.node.totalPriceV2.amount}
                totalItems={LineItemsHelper.getNumberOfItemsInCheckout(data.node.lineItems)}
                showShipping={false}
                onlyNotCompleted={true}
                class={'mt-6 first:mt-0'}
                innerClass={'p-6 md:p-4 md:p-12'}
              />
            </div>
          </div>
          <div className="col md:w-1/3 md:pl-16 mt-8 md:mt-0">
            <CartTotal
              checkoutUrl={data.node.webUrl}
              total={total}
              subTotal={subTotal}
              promotionsTotal={promotionsTotal}
              calculatedShippingCost={calculatedShippingCost}
              getCheckoutPromotions={getCheckoutPromotions(data.node.discountApplications, data.node.lineItemsSubtotalPrice.amount)}
              openCheckout={openCheckout}
              handleAddDiscountCode={handleAddDiscountCode}
              activeDiscountCode={activeDiscountCode}
              discountErrors={discountErrors}
              removeDiscountCode={removeDiscountCode}
              setDiscountCode={setDiscountCode}
              element={props.element}
            ></CartTotal>
          </div>
        </div>
      }
    </div>
  );
};

export default CartOverview;
