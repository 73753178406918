import React, { Component } from 'react';
import LoadingSpinner from '../loadingSpinner';
import axios from 'axios';

class OutOfStockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      loading: false,
      success: null,
    };

    this.errorMessage = window.shopTranslations.emailInvalid;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.sendToKlaviyo = this.sendToKlaviyo.bind(this);
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    if (!this.isValidEmail(this.state.email)) {
      this.setState({ error: this.errorMessage });
    } else {
      this.setState({ error: false });
      this.sendToKlaviyo();
    }
    event.preventDefault();
  }

  isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  sendToKlaviyo() {
    this.setState({ loading: true });

    if (typeof window.klaviyoCompanyId !== 'undefined') {

      const payload = {
        "data": {
          "type": "back-in-stock-subscription",
          "attributes": {
            "profile": {
              "data": {
                "type": "profile",
                "attributes": {
                  "email": this.state.email,
                }
              }
            },
            "channels": ["EMAIL"],
          },
          "relationships": {
            "variant": {
              "data": {
                "type": "catalog-variant",
                "id": `$shopify:::$default:::${this.props.variantId}`
              }
            }
          }
        }
      }

      axios.post(
          `//a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${window.klaviyoCompanyId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "revision":"2023-12-15"
            }
          }
        )
        .then(res => {
          this.setState({ loading: false });
          if (res.status === 202) {
            this.setState({ success: true });
          } else {
            this.setState({ success: false });
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ loading: false });
          this.setState({ success: false });
        });
    }

  }

  render() {
    if (this.state.loading) {
      return <div className="relative min-h-32">
        <div className="absolute top-0 left-0 w-full h-full px-6 flex items-center justify-center">
          <LoadingSpinner /></div>
      </div>;
    }
    if (this.state.success !== null) {
      if (this.state.success) {
        return <div
          className={'text-green-600 mt-1.5'}>{window.shopTranslations.klaviyoSuccess}</div>;
      } else {
        return <div className={'text-red-dark mt-1.5'}>{window.shopTranslations.klaviyoError}</div>;
      }
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={'form-field'}>
          <input type="email" name="email" id="email" value={this.state.email}
                 onChange={this.handleChange} placeholder={window.shopTranslations.emailAddress}
                 className={`form-control bg-green-lightest ${this.state.error ? 'border-b-red-dark' : ''}`} />
          {this.state.error &&
            <div className={'text-red-dark mt-1.5'}>{this.state.error}</div>
          }
        </div>
        <div className="mt-4">
          <input type="submit" className={'button button--green'}
                 value={window.shopTranslations.notifyMeButton} />
        </div>
      </form>

    );
  }
}

export default OutOfStockForm;
