import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRICE_OF_VARIANT } from '../../queries/get-price-of-variant';
import ShopifyHelper from '../../helpers/ShopifyHelper';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import { useSelector } from 'react-redux';

const MarketingMessageProductPrice = (props) => {
  const currencyCode = useSelector((state) => state.shopify.currencyCode);
  const { loading, error, data } = useQuery(GET_PRICE_OF_VARIANT, {
    variables: { id: ShopifyHelper.getStorefrontId(props.variantId) },
  });

  if (loading) return '';
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );
  if (!data.node) return '';
  return (
    <div className={"flex flex-wrap items-center text-red-500 font-bold text-3xl"}>
      <div className="">
        {CurrencyHelper.GetFormattedPrice({
          amount: 0,
          currencyCode,
          digits: 0,
        })}
      </div>
      <div className="ml-3 line-through opacity-50">
        {CurrencyHelper.GetFormattedPrice(data.node.priceV2)}
      </div>
    </div>
  )
}

export default MarketingMessageProductPrice;
