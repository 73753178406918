import { createSlice } from '@reduxjs/toolkit';

export const shopifySlice = createSlice({
  name: 'shopify',
  initialState: {
    id: 0,
    lineItems: [],
    lineItemIds: [],
    webUrl: '',
    totalPrice: 0,
    numberOfItemsInCheckout: 0,
    favoriteCount: 0,
    currencyCode: 'EUR',
    loading: false,
    activeDiscountCode: '',
    doUpdateMiniCart: false,
  },
  reducers: {
    updateId: (state, action) => {
      state.id = action.payload;
    },
    updateLineItems: (state, action) => {
      state.numberOfItemsInCheckout = action.payload.edges.reduce(function (acc, obj) {
        return acc + obj.node.quantity;
      }, 0);
      state.lineItemIds = action.payload.edges.map(obj => obj.node.id);
      state.lineItems = action.payload;
    },
    updateWebUrl: (state, action) => {
      state.webUrl = action.payload;
    },
    updateTotalPrice: (state, action) => {
      state.totalPrice = action.payload.amount;
      state.currencyCode = action.payload.currencyCode;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateActiveDiscountCode: (state, action) => {
      state.activeDiscountCode = action.payload;
    },
    updateMiniCart: (state, action) => {
      state.doUpdateMiniCart = action.payload;
    },
    updateFavorites: (state, action) => {
      const favoritesStorageKey = `${window.languageLocale}-shopify-remescar-cart-wishlist`;
      const favorites = JSON.parse(localStorage.getItem(favoritesStorageKey));
      state.favoriteCount = favorites.length;
    },
    resetState: (state, action) => {
      state.id = 0;
      state.lineItems = [];
      state.lineItemIds = [];
      state.webUrl = '';
      state.totalPrice = 0;
      state.favoriteCount = 0;
      state.numberOfItemsInCheckout = 0;
      state.currencyCode = 'EUR';
      state.loading = false;
      state.activeDiscountCode = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateId,
  updateLineItems,
  updateWebUrl,
  updateTotalPrice,
  updateLoading,
  updateActiveDiscountCode,
  resetState,
  updateMiniCart,
  updateFavorites
} = shopifySlice.actions;

export default shopifySlice.reducer;
