import { gql } from "@apollo/client";
import { CHECKOUT_FRAGMENT, LINEITEM_CHECKOUT_FRAGMENT, PRICE_FRAGMENT } from './fragments';

export const REMOVE_LINE_ITEM = gql`
  ${LINEITEM_CHECKOUT_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        ... CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
