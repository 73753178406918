import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { updateLineItems, updateLoading, updateTotalPrice } from '../../store/shopifySlice';
import { useMutation } from '@apollo/client';
import { ADD_TO_CART } from '../../queries/add-to-cart';
import { UPDATE_LINE_ITEMS } from '../../queries/update-line-items';
import { REMOVE_LINE_ITEM } from '../../queries/remove-line-item';
import LineItemsHelper from '../../helpers/LineItemsHelper';
import ShopifyHelper from '../../helpers/ShopifyHelper';

const EditProductInCart = (props) => {
  const variantId = props.variantId;
  const dispatch = useDispatch();
  const checkoutId = useSelector((state) => state.shopify.id);
  const freeProducts = props.freeProducts;
  const cartItemsFreeProducts = props.cartItemsFreeProducts;

  const [quantity, setQuantity] = useState(props.quantity);

  const [addToCart] = useMutation(ADD_TO_CART);
  const [editLineItems] = useMutation(UPDATE_LINE_ITEMS);

  const maxItems = 5;
  const range = Array.from({length: maxItems}, (_, i) => i + 1);

  useEffect(() => {
    setQuantity(props.quantity);
  }, [props]);

  const [checkoutLineItemsRemove] = useMutation(REMOVE_LINE_ITEM, {
    onCompleted({ checkoutLineItemsRemove: { checkout: { totalPriceV2, lineItems } } }) {
      dispatch(updateTotalPrice(totalPriceV2));
      dispatch(updateLineItems(lineItems));
    }
  });

  function sendItemToShopify(variantId, quantity, checkoutId, callback, replace = true) {
    if (replace) {
      editLineItems({
        variables: {
          lineItems: [{ id: props.id, variantId: variantId, quantity: quantity }],
          checkoutId: checkoutId,
        }
      }).then(res => {
        const {data: { checkoutLineItemsUpdate: { checkout: { id, lineItems, totalPriceV2} } }} = res;

        dispatch(updateTotalPrice(totalPriceV2));
        dispatch(updateLoading(false));

        props.refetch();

        callback(totalPriceV2.amount, lineItems);
      })
    } else {
      addToCart({
        variables: {
          lineItems: [{ variantId: variantId, quantity: quantity }],
          checkoutId: checkoutId,
        }
      }).then(res => {
        const {data: { checkoutLineItemsAdd: { checkout: { id, lineItems, totalPriceV2} } }} = res;

        dispatch(updateTotalPrice(totalPriceV2));
        dispatch(updateLoading(false));

        props.refetch();

        callback(totalPriceV2.amount, lineItems);
      })
    }
  }

  function updateLineItem(quantity) {
    dispatch(updateLoading(true));

    sendItemToShopify(variantId, quantity, checkoutId, (totalAmount, lineItems) => {
      props.checkPromo(totalAmount, lineItems);
    })
  }

  function checkIfPromoItem(variantId) {
    if (Number(props.price) === 0.0) {
      return true;
    }

    const allFreeProducts = [...freeProducts, ...cartItemsFreeProducts];
    for (const freeProduct of allFreeProducts) {
      if (variantId === ShopifyHelper.getStorefrontId(freeProduct.id)) {
        return true;
      }
    }

    return false;
  }

  return (
    <div className="flex items-center edit-checkout-item">
      { !checkIfPromoItem(variantId) &&
        <div>
          <select className={"font-bold text-green border border-green-500 rounded bg-white px-2 py-0.5"} value={quantity} onChange={ (event) => updateLineItem(parseInt(event.target.value)) }>
            { range.map((item, key) => {
              return <option value={item} key={key}>{item}</option>
            }) }
          </select>
        </div>
      }

      { checkIfPromoItem(variantId) &&
        <div
          className={ 'min-w-14 mb-0 mx-2 font-bold' }
        >
          {quantity} x
        </div>
      }
    </div>
  )
}

export default EditProductInCart;
