import React, { createRef, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import NotifyIcon from '../icons/NotifyIcon';
import CustomPopup from '../CustomPopup';
import OutOfStockForm from './OutOfStockForm';
import OutOfStockPopup from './OutOfStockPopup';

const OutOfStockButton = (props) => {

  const [popupVisible, setPopupVisible] = useState(false);

  const disableScroll = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    document.getElementsByTagName('html')[0].style.removeProperty('overflow');
  };

  const setVisibility = (e) => {
    setPopupVisible(e);
    if (e) {
      disableScroll();
    } else {
      enableScroll();
    }
  };

  return (
    <>
      <button
        className={`${props.class} ${props.iconOnly ? 'add-to-cart-button-wrapper--small flex justify-center items-center px-2 md:px-5 pt-2.5 pb-2 md:py-3 text-white text-base md:text-3xl font-semibold min-h-10.5 md:min-h-12 bg-white' : ''}`}
        onClick={(e) => setVisibility(true)}>
        {props.iconOnly &&
          <div className="w-4 h-4 text-black">
            <NotifyIcon />
          </div>
        }
        {!props.iconOnly &&
          <>
            {window.shopTranslations.notifyMeButton}
            <div className="w-4 h-4 ml-2.5"><NotifyIcon /></div>
          </>
        }
      </button>
      <OutOfStockPopup variantId={props.variantId} onClose={setVisibility} show={popupVisible} />
    </>
  );

};

export default OutOfStockButton;
