export default class GtmHelper {
  static crossSellGTM = (product, props) => {
    window.dataLayer = window.dataLayer || [];

    if (props.onCartPage) {
      let cartProductTitles = 'empty';
      if (window.cartItemsTitles && window.cartItemsTitles.length) {
        cartProductTitles = window.cartItemsTitles.join(", ");
      }
      const GaCartRecommendedProductsEvent = {
        event: "CrossSell",
        category: "Ecommerce",
        action: `Cross sell Cart - ${cartProductTitles}`,
        label: `Add to cart - ${product.title}`
      }
      window.dataLayer.push(GaCartRecommendedProductsEvent);
    }

    if (props.isRelatedButton) {
      const GaRelatedProductEvent = {
        event: "CrossSell",
        category: "Ecommerce",
        action: `Cross sell Pop-up - ${props.parentTitle}`,
        label: `Add to cart - ${product.title}`
      }
      window.dataLayer.push(GaRelatedProductEvent);
    }

    if (props.onProductDetailPage) {
      const currentProductName = window.currentProductTitle;
      const GaCartRecommendedProductsEvent = {
        event: "CrossSell",
        category: "Ecommerce",
        action: `Cross sell PDP - ${currentProductName}`,
        label: `Add to cart - ${product.title}`
      }
      window.dataLayer.push(GaCartRecommendedProductsEvent);
    }
  }

  static extractProduct = (b64id, data, type) => {
    const items = data.data[type].checkout.lineItems.edges;
    const item = items.filter((c) => {
      return c.node.variant.id === b64id;
    })[0];

    return item.node;
  };
}
