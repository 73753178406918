import ShopifyHelper from '../helpers/ShopifyHelper';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_VARIANTS } from '../queries/get-product-variants';
import React, { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import VariantContext from './VariantContext';

const Variants = (props) => {
  const productId = ShopifyHelper.getProductId(props.productId);
  const { loading, error, data} = useQuery(GET_PRODUCT_VARIANTS, {
    variables: {
      id: productId,
    },
  });

  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const { contextVariant, setContextVariant } = useContext(VariantContext);

  const enabledVariants = JSON.parse(props.enabledVariants);

  if (loading) return null;
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );

  const { variants, options } = data.product;

  if (selectedOptions && selectedOptions.length === 0) {
    const selectedOptionsObj = data.product.options.reduce((prev,   curr, i) => {
      prev[curr.name] = curr.values[0]
      return prev
    }, {})

    setSelectedOptions(selectedOptionsObj)
  }

  const handleVariantChange = (e) => {
    const target = e.target;
    selectedOptions[target.name] = target.value;

    const selectedVariant = variants.edges.find((variant) => {
      return variant.node.selectedOptions.every((selectedOption) => {
        return selectedOptions[selectedOption.name] === selectedOption.value.valueOf();
      })
    })
    setContextVariant(selectedVariant);
    setImage(selectedVariant)
  };

  const getVariantByOptionValue = (optionValue) => {
    // let variant = false;
    return variants.edges.find((item) => {
      return item.node.selectedOptions.every((selectedOption) => {
        return optionValue === selectedOption.value.valueOf()
      })
    });
  }

  const setImage = (selectedVariant) => {
    const firstImage = document.querySelector('[data-product-gallery-main] [data-image] img');
    firstImage.setAttribute('src', selectedVariant.node.image.url);
  }

  if (variants.edges.length > 1 && options && enabledVariants.length) {
    return (
      createPortal(
        <div>
          <div className={`flex flex-wrap flex-col items-start w-full border-t border-gray-500 mt-6 pt-6`}>
            {
              options.map((option, i) => {
                const enabledVariant = enabledVariants.find(variant => variant.name === option.name);
                if (enabledVariant) {
                  return (
                    <div key={option.name} className={'mt-4 first:mt-0'}>
                      <div className={'font-bold'}>{enabledVariant.label}</div>
                      <div className={`mt-2 flex flex-wrap items-start`}>
                        {option.values.map((value) => {
                          const variant = getVariantByOptionValue(value);
                          const isInStock = (variant.node.quantityAvailable > 0 || variant.node.currentlyNotInStock);
                          const selected = selectedOptions[option.name] === value;
                          return (
                            <div className={`mr-4`} key={`${option.name}-${value}-wrapper`}>
                              {
                                isInStock ?
                                  <label htmlFor={`${option.name.toLowerCase()}-${value.toLowerCase()}`} key={`${option.name}-${value}-label`}>
                                    <input type="radio" name={option.name} id={`${option.name.toLowerCase()}-${value.toLowerCase()}`} value={value} key={`${option.name}-${value}`} className={'form-radio__input'} checked={selected} onChange={handleVariantChange} />
                                    <div className={`button cursor-pointer ${selected ? 'button--green' : ''} `}>
                                      {value}
                                    </div>
                                  </label>
                                  :
                                  <div className={`button cursor-default text-white bg-[#999] border-0`}>
                                    {value}
                                  </div>
                              }

                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>,
        props.element,
      )
    )
  }

  return <></>
}

export default Variants;
