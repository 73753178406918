import { gql } from "@apollo/client";
import { CHECKOUT_FRAGMENT, LINEITEM_CHECKOUT_FRAGMENT, PRICE_FRAGMENT } from './fragments';

export const UPDATE_LINE_ITEMS = gql`
  ${LINEITEM_CHECKOUT_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${CHECKOUT_FRAGMENT}
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!,
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ... CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
