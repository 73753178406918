import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateId, updateMiniCart, updateWebUrl } from "../store/shopifySlice";
import { ADD_TO_CART } from "../queries/add-to-cart";
import { CREATE_CHECKOUT } from "../queries/create-checkout";
import { IS_IN_STOCK_QUERY } from "../queries/check-if-in-stock";
import ShopifyHelper from "../helpers/ShopifyHelper";
import LineItemsHelper from "../helpers/LineItemsHelper";
import GtmHelper from '../helpers/GtmHelper';
import GetPriceOfVariant from '../components/GetPriceOfVariant';
import OutOfStockButton from '../components/OutOfStock/OutOfStockButton';
import {trackAddedToCart} from '../helpers/KlaviyoOnsite';

const BuyButton = (props) => {
  const preOrderClass = 'pre-order';
  const soonAvailableClass = 'soon-available';
  const noStockClass = 'no-stock';

  const checkoutId = useSelector((state) => state.shopify.id);
  const currencyCode = useSelector((state) => state.shopify.currencyCode);

  const [addToCart] = useMutation(ADD_TO_CART);
  const [createCheckout] = useMutation(CREATE_CHECKOUT);
  const dispatch = useDispatch();

  const [buttonClicked, setButtonClicked] = useState(false);

  const customButtonId = props.customButtonId;
  const freeProducts = props.freeProducts;

  const { loading, error, data } = useQuery(IS_IN_STOCK_QUERY, {
    variables: { id: ShopifyHelper.getStorefrontId(props.variantId) },
  });

  useEffect(() => {
    const rootWrapper = document.getElementById('buy-button-wrapper');
    const realBuyButtonWrapper = document.getElementById(`parent-wrapper-of-button-${props.craftId}`);

    if (rootWrapper) {
      rootWrapper.classList.remove(preOrderClass);
      rootWrapper.classList.remove(soonAvailableClass);
      rootWrapper.classList.remove(noStockClass);
    }

    if (realBuyButtonWrapper) {
      realBuyButtonWrapper.classList.remove(preOrderClass);
      realBuyButtonWrapper.classList.remove(soonAvailableClass);
      realBuyButtonWrapper.classList.remove(noStockClass);
    }
  }, []);

  if (loading) return '';
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );

  const checkPromo = (totalPrice, inputCheckoutId, lineItems) => {
    for (const freeProduct of freeProducts) {
      if (freeProduct.min <= +totalPrice) {
        if (!LineItemsHelper.checkIfLineItemExists(lineItems, freeProduct.id)) {
          sendItemToShopify(inputCheckoutId, freeProduct.id, () => {});
        }
      }
    }
  }

  function sendItemToShopify(checkoutId, variantId, callBack) {
    const base64VariantId = ShopifyHelper.getStorefrontId(variantId);

    if (checkoutId) {
      const options = {
        variables: {
          lineItems: [{ variantId: base64VariantId, quantity: 1 }],
          checkoutId: checkoutId,
        }
      };

      addToCart(options).then(res => {
        const {data: { checkoutLineItemsAdd: { checkout: { id, webUrl, totalPriceV2, lineItems } } }} = res;
        callBack(totalPriceV2.amount, id, lineItems);
        const product = GtmHelper.extractProduct(
          base64VariantId,
          res,
          "checkoutLineItemsAdd"
        )
        setButtonClicked(true);
        trackAddedToCart(product, 1, props.productUrl, webUrl);
        GtmHelper.crossSellGTM(product, props);
      });
    } else {
      const options = {
        variables: {
          lineItems: [{ variantId: base64VariantId, quantity: 1 }],
        }
      };

      createCheckout(options).then(res => {
        const {data: { checkoutCreate: { checkout: { id, webUrl, totalPriceV2, lineItems } } }} = res;

        dispatch(updateId(id));
        dispatch(updateWebUrl(webUrl));

        callBack(totalPriceV2.amount, id, lineItems);
        const product = GtmHelper.extractProduct(
          base64VariantId,
          res,
          "checkoutCreate"
        )
        setButtonClicked(true);
        trackAddedToCart(product, 1, props.productUrl, webUrl);
        GtmHelper.crossSellGTM(product, props);
      });
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: currencyCode,
        add: {
          actionField: {
            list: 'Shopping cart',
          },
          products: [{ variantId: base64VariantId, quantity: 1 }],
        },
      },
    });
  }

  function addItemToCart() {
    sendItemToShopify(checkoutId, props.variantId, (totalPrice, checkoutId, lineItems) => {

      if (freeProducts) {
        checkPromo(totalPrice, checkoutId, lineItems);
      }

      dispatch(updateMiniCart(true));
    });
  }

  if (data && data.node) {
    const isInStock = (data.node.quantityAvailable > 0 || data.node.currentlyNotInStock);

    return (
      <>
        <div className={`w-full flex ${(isInStock && !buttonClicked) ? 'cursor-pointer' : ''}`} {...(isInStock && !buttonClicked && { onClick: () => addItemToCart() })} id={`related-product-buy-button--${props.variantId}`}>
          <GetPriceOfVariant
            variantId={props.variantId}
            inStock={isInStock}
          ></GetPriceOfVariant>
          {!isInStock &&
            <OutOfStockButton
              element={false}
              renderDefault={true}
              variantId={props.variantId}
              iconOnly={true}
              class={'button--white'}
            />
          }
          {isInStock &&
            <button
              className={ `react-add-to-cart mt-0 p-3 bg-white ${ buttonClicked ? 'clicked' : '' }` }
              data-custom-id={ customButtonId || '' }
            >
              <span className={'w-[18px] md:w-[27px] h-[16px] md:h-[24px] text-black block'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 27 24'><path d="M23.659 15a1.125 1.125 0 0 0 1.1-.876l2.216-9.75A1.125 1.125 0 0 0 25.874 3H7.463L7.033.9a1.125 1.125 0 0 0-1.1-.9H1.125A1.125 1.125 0 0 0 0 1.125v.75A1.125 1.125 0 0 0 1.125 3H4.4l3.293 16.1a2.625 2.625 0 1 0 3.143.4h9.827a2.624 2.624 0 1 0 2.981-.488l.259-1.138a1.125 1.125 0 0 0-1.1-1.374H10.224L9.917 15Zm-4.534-7.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-2.25V12a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-1.875h-2.25a.75.75 0 0 1-.75-.75v-.75a.75.75 0 0 1 .75-.75h2.25V6a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v1.875Z" fill="currentColor"/></svg>
              </span>
            </button>
          }
        </div>
      </>
    );
  }

  return <></>
}

export default BuyButton;
