import { useQuery } from "@apollo/client";
import React from "react";
import CurrencyHelper from "../helpers/CurrencyHelper";
import ShopifyHelper from "../helpers/ShopifyHelper";
import { GET_PRICE_OF_VARIANT } from "../queries/get-price-of-variant";

const GetPriceOfVariant = (props) => {
  const { loading, error, data } = useQuery(GET_PRICE_OF_VARIANT, {
    variables: { id: ShopifyHelper.getStorefrontId(props.variantId) },
  });

  if (loading) return '';
  if (error) console.log(error.message);
  if (error) return (
    <></>
  );
  if (!data.node) return '';

  let bgClass = data.node.compareAtPriceV2 ? 'bg-red-500' : 'bg-green-600';

  if (!props.inStock) {
    bgClass = '!bg-[#9EB5C1]';
  }

  return (
    <>
    {data.node &&
      <div
        className={`flex justify-center items-center flex-grow ${bgClass} text-white text-base md:text-4xl font-semibold`}>
        <div className="price-of-variant">
          { CurrencyHelper.GetFormattedPrice(data.node.priceV2) }
          {data.node.compareAtPriceV2 &&
            <span className="ml-2.5 line-through text-sm md:text-2xl opacity-50">{ CurrencyHelper.GetFormattedPrice(data.node.compareAtPriceV2) }</span>
          }
        </div>
      </div>
    }
    </>
  );
}

export default GetPriceOfVariant;
