import React from 'react';
import Progressbar from '../Progressbar';
import MarketingMessageProductPrice from './MarketingMessageProductPrice';

const MarketingMessage = (props) => {

  const colClass = props.banner.product ? 'lg:w-5/6' : '';

  return (
    <div className={`bg-green-lightest z-100 banner-message rounded ${props.innerClass} ${props.banner.product ? 'relative mt-8': ''}`}>
      {
        props.banner.product &&
        <div className={"w-4 h-4 md:w-8 md:h-8 text-green-500 absolute -top-2 -left-2 md:-top-4 md:-left-4"}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.729 31.729"><path fill="currentColor" d="M19.716 19.659v12.07h-7.705v-12.07H0v-7.646h12.011V0h7.705v12.012h12.012v7.646Z" data-name="Path 1135"/></svg>
        </div>
      }
      <div className={'flex flex-wrap md:flex-nowrap items-center'}>
        {
          props.banner.image &&
          <div className={`flex flex-shrink-0 ${colClass}`}>
            <div dangerouslySetInnerHTML={{ __html: props.banner.image }}
                 className={'flex-shrink-0 sm:mr-6'}></div>
            {
              props.banner.product &&
              <div className={"sm:mr-8"}>
                <div className="font-bold">{props.banner.product.title}</div>
                <div className="mt-2">{props.banner.product.intro}</div>
                <div className="mt-4">
                  <MarketingMessageProductPrice variantId={props.banner.product.variantId}/>
                </div>
              </div>
            }
          </div>
        }
        <div className="flex-shrink flex-grow">
          <div className={`${colClass} mt-4 md:mt-3 lg:mt-0`} dangerouslySetInnerHTML={{ __html: props.message }}></div>
          <Progressbar percentage={props.percentage} />
        </div>
      </div>
    </div>
  )
}

export default MarketingMessage;
