export const GET_URL_BY_SHOPIFY_ID = `
  query getUrlByShopifyId($id: [QueryArgument], $siteId: [QueryArgument]) {
    entries(section: "products", shopifyStorefrontVariantId: $id, siteId: $siteId) {
      url
      ... on products_products_Entry {
        title
        shopifyStorefrontVariantId
        productImages(limit: 1) {
          title
          url
        }
        routine {
          ... on routine_BlockType {
            morning
            evening
          }
        }
        multiBuy {
          quantity
          discount
        }
      }
    }
  }
`;
