import React, { useEffect } from 'react';
import {createPortal} from 'react-dom';
import { useSelector } from 'react-redux';

const favoriteCount = (props) => {
  const favoritesCount = useSelector((state) => state.shopify.favoriteCount);

  if (props.elements.length && typeof favoritesCount !== 'undefined') {
    props.elements.forEach((element) => {
      if (favoritesCount !== 0) {
        element.innerHTML = favoritesCount
      } else {
        element.innerHTML = ''
      }
    });
  }
  return <></>
}

export default favoriteCount;
