export default class ShopifyHelper {
  static getStorefrontId = (productVariantId) => {
    return `gid://shopify/ProductVariant/${productVariantId}`;
  };

  static getProductId = (productId) => {
    return `gid://shopify/Product/${productId}`;
  }

  static getShopifyId = (id) => {
    return id.split("/").pop();
  };
}
