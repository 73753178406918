import React from 'react';
import CheckMark from '../icons/CheckMark';

const ProductPromotions = (props) => {

  const nextPromotion = () => {
    if (props.multiBuy) {
      const nextMultiBuy = props.multiBuy
        .filter((multiBuy) =>
          multiBuy.quantity > props.quantity
        )[0];
      if (nextMultiBuy) {
        const {discount, quantity} = nextMultiBuy;
        return window.shopTranslations.cartProductPromotionMessage
          .replace('{quantity}',quantity)
          .replace('{percentage}', `${discount}%`)
      }
    }
    return false
  }

  return (
    <>
      {nextPromotion() &&
        <div className="bg-green-lightest inline-flex items-center rounded p-2 mt-2">
          <div className="w-4 h-4 mr-2 text-green-500">
            <CheckMark/>
          </div>
          <div>{nextPromotion()}</div>
        </div>
      }
    </>
  );

};

export default ProductPromotions;
