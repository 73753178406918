import React from "react";

const EmptyCart = () => {
  return (
    <>
      <div className={ 'border-b' }>
        <div className={ 'row py-8 flex items-center' }>
          <div className={ 'col' }>
            <p>{ window.shopTranslations.cartNoResultsLabel }</p>
            <a className={ 'button button--green' }
               href={ window.shopTranslations.cartProductCtaUrl }>{ window.shopTranslations.cartProductsCtaText }</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmptyCart;
