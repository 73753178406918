import { gql } from "@apollo/client";
import { LINEITEM_CHECKOUT_FRAGMENT, PRICE_FRAGMENT } from "./fragments";

export const CREATE_CHECKOUT = gql`
  ${LINEITEM_CHECKOUT_FRAGMENT}
  ${PRICE_FRAGMENT}
  mutation createNewCheckout($lineItems: [CheckoutLineItemInput!]!) {
    checkoutCreate(input: { lineItems: $lineItems }) {
      checkout {
        id
        webUrl
        totalPriceV2 {
          ...PriceFragment
        }
        lineItems(first: 250) {
          edges {
            node {
              ...CheckoutLineItemFragment
            }
          }
        }
      }
    }
  }
`;
