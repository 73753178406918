import React, { Component } from "react";
import ShopifyHelper from "../helpers/ShopifyHelper";
import CurrencyHelper from "../helpers/CurrencyHelper";
import { GET_URL_BY_SHOPIFY_ID } from "../queries/get-url-by-shopify-id";

class FillBundleImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
    };
  }

  componentDidMount() {
    const { id, element } = this.props;

    // get product url based on shopifyId
    fetch("/craft-api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GET_URL_BY_SHOPIFY_ID,
        variables: {
          id: id,
          siteId: window.currentSiteId,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        try {
          const { url } = res.data.entries[0].productImages[0];
          const { title } = res.data.entries[0];

          element.querySelector("img").setAttribute("src", url);
          element.querySelector(".bundle-shopify-title").append(title);
        } catch {
          console.log("Couldn't get product image");
        }
      });
  }

  render() {
    return <></>;
  }
}

export default FillBundleImage;
