import React from "react";

const CloseIcon = (props) => {
  const size = props.small ? 14 : 20;

  return (
    <svg width={ `${size}px` } height={ `${size}px` } viewBox="0 0 30 30">
      <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M7.5 22.5L22.5 7.5M7.5 7.5l15 15"></path>
      </g>
    </svg>
  )
}

export default CloseIcon;
