export const GET_SHOPICONS = `
query getShopIcons($siteId: [QueryArgument]) {
  globalSet(siteId: $siteId) {
    ... on shopify_GlobalSet {
      shopifyIcons {
        title
        url
      }
    }
  }
}
`;
