import React, { useState } from "react";
import CloseIcon from "./icons/close";

const Message = (props) => {
  const message = props.message;
  const [showMessage, setShowMessage] = useState(true);

  const handleClose = (e) => {
    e.preventDefault();
    setShowMessage(false);
  }

  return (
    showMessage && <div className={ 'p-4 bg-red-light relative' }>
      { message }
      <a href="" className={'p-2 absolute'} style={{ top: 0, right: 0}} onClick={(event) => handleClose(event)}>
        <CloseIcon />
      </a>
    </div>
  )
}

export default Message;
