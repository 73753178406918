import React from 'react';

import PriceHelper from '../../helpers/PriceHelper';
import FreeShipping from '../Cart/FreeShipping';
import MarketingMessage from './MarketingMessage';

const MarketingMessages = (props) => {
  const totalPrice = props.total;
  const totalItems = props.totalItems;
  const parseMessage = (message, max) => {
    const maxMinTotal = ((Math.round((max - totalPrice) * 100)) / 100).toString();
    return message.replace('{max-total}', maxMinTotal);
  };

  const parseItemsMessage = (message, min) => {
    const minTotal = min - totalItems;
    let parsedMessage = message.replace('{min-total}', minTotal);
    if (minTotal > 1) {
      parsedMessage = parsedMessage.replace('{product_translation}', window.shopTranslations.productPlural);
    } else {
      parsedMessage = parsedMessage.replace('{product_translation}', window.shopTranslations.productSingular);
    }
    return parsedMessage;
  };

  return (
    <>
      {props.showShipping && props.freeShippingPercentage &&
        <div className={'p-3 sm:w-1/2'}>
          <div className={'bg-green-lightest z-100 p-4 banner-message rounded sm:h-full'}>
            <FreeShipping
              percentage={props.freeShippingPercentage}
              amountToSpend={props.freeShippingToSpend}
              currencyCode={props.currencyCode}
            />
          </div>
        </div>
      }
      {
        window.marketingBannersMinMax.map((banner, index) => {
          let percentage = 100;
          if (banner.max && banner.max !== 9999999 && totalPrice < banner.max) {
            percentage = PriceHelper.PercentageHelper(totalPrice, banner.max);
          }

          let query = totalPrice >= banner.min && totalPrice <= banner.max;
          if (props.onlyNotCompleted) {
            query = totalPrice <= banner.max;
          }

          if (props.onlyNotCompleted && percentage === 100) {
            return null;
          }

          if (query) {
            return <div
              key={`bannermessage-${index}`}
              className={props.class}
            >
              <MarketingMessage
                banner={banner}
                hasProduct={banner.product ?? false}
                message={parseMessage(banner.text, banner.max)}
                innerClass={props.innerClass}
                percentage={percentage}
              />
            </div>;
          }
        })
      }
      {
        window.marketingBannersNumberOfItems &&
        window.marketingBannersNumberOfItems.map((banner, index) => {
          let percentage = 100;
          if (banner.min && totalItems < banner.min) {
            percentage = PriceHelper.PercentageItemsHelper(totalItems, banner.min);
          }

          let query = totalItems < banner.min;

          if (props.onlyNotCompleted) {
            query = totalItems < banner.min && !banner.requirementForActionMet;
          }

          if (!props.onlyNotCompleted && banner.requirementForActionMet) {
            if (percentage !== 100) {
              return null;
            } else {
              query = banner.requirementForActionMet;
            }
          }

          if (query) {
            return <div
              key={`banner-items-message-${index}`}
              className={props.class}
            >
              <MarketingMessage
                banner={banner}
                hasProduct={banner.product ?? false}
                message={parseItemsMessage(banner.text, banner.min)}
                innerClass={props.innerClass}
                percentage={percentage}
              />
            </div>;
          }
        })
      }
    </>
  );
};

export default MarketingMessages;
