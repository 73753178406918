import React from 'react';

const NotifyIcon = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16"><path fill="currentColor" d="M7 16a2 2 0 0 0 2-2H5a2 2 0 0 0 2 2Zm6.731-4.678C13.127 10.673 12 9.7 12 6.5a4.935 4.935 0 0 0-4-4.849V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v.651A4.935 4.935 0 0 0 2 6.5C2 9.7.873 10.673.269 11.322A.976.976 0 0 0 0 12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 .976.976 0 0 0-.269-.678Z" data-name="Path 1281"/></svg>
    </>
  )
}
export default NotifyIcon
