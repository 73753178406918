import React from 'react';
import BuyButton from '../../ui/BuyButton';
import RoutineIcons from '../icons/Routine';
import FavoriteButton from '../../components/FavoriteButton';

const RelatedProduct = (props) => {

  const localStorageKey = `${window.languageLocale}-shopify-remescar-cart`;
  const favoritesStorageKey = localStorageKey + '-wishlist';
  const favoriteIds = JSON.parse(localStorage.getItem(favoritesStorageKey));

  return (
    <div className={"flex flex-col justify-between bg-gray-150 text-center h-full relative shopify-product overflow-hidden"}>
      {
        (props.label || (props.routine && props.routine.length > 0)) &&
        <span className={"flex flex-col absolute top-1.25 md:top-2.5 left-1.25 md:left-3.5 z-[2]"}>
              {props.label && <span className="label mb-1.25 md:mb-2">{props.label}</span>}
          {props.routine.length > 0 && <RoutineIcons routine={props.routine[0]} orientation={"horizontal"}></RoutineIcons>}
        </span>
      }
      <FavoriteButton
        productId={props.productId}
        isFavorited={
          favoriteIds !== null ? favoriteIds.includes(props.productId) : false
        }
        localStorageKey={favoritesStorageKey}
      >
      </FavoriteButton>
      <div className="w-full p-1.25 md:p-3 relative">
        <a className="relative z-1" href={props.url} data-tracking-uid={props.slug}>
          <img src={props.imageUrl} alt={props.imageTitle} />
        </a>
        <div className="w-full h-auto absolute bottom-0 left-0 z-0 pointer-events-none text-green-200">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 55"><path d="M0 39.329 140 0v55H0V39.329Z" fill="currentColor"/></svg>
        </div>
      </div>
      <div
        className="flex flex-col justify-between p-1.25 md:p-3 pt-0 md:pt-0 flex-grow bg-green-200">
        <div className="parent-wrapper-of-button" id={`parent-wrapper-of-button-${props.id}`}>
          <div className="block font-bold text-sm md:text-lg mt-3.5">{props.title}</div>
          <div className="richtext text-2xs md:text-base md:mt-1.25 px-2.5 md:px-3" dangerouslySetInnerHTML={{ __html: props.intro }}></div>
          <a href={props.url}
             className={"hidden md:inline-block text-base font-normal text-gray-500 underline mt-3"}
             data-tracking-uid={props.slug}>{window.shopTranslations.readMore}</a>
        </div>
        <div className="mt-6">
          <BuyButton
            productId={ props.productId }
            variantId={ props.variantId }
            productTitle={ props.title }
            productUrl={ props.url }
            craftId={ props.craftId }
            showAlert={ false }
            relatedId={ props.craftId }
            parentTitle={props.parentTitle}
            freeProducts={ props.freeProducts }
            isRealButton={ false }
            customButtonId={ `custom-button-id-${props.craftId}` }
            isRelatedButton={ true }
          />
        </div>
      </div>
    </div>
  )
}

export default RelatedProduct;
