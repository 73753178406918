import React, { Component } from 'react';
import PaymentOptions from '../icons/Paymentoptions';
import CartDiscounts from './CartDiscounts';
import OrderButton from './OrderButton';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class CartTotal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      total,
      subTotal,
      checkoutUrl,
      calculatedShippingCost,
      discountCode,
      activeDiscountCode,
      discountErrors,
      promotionsTotal
    } = this.props;

    return (
      <>
        <div className="hidden md:block">
          <div className="flex justify-between">
            <div className="font-bold text-xl">{window.shopTranslations.cartSubtotal}</div>
            <div className="font-bold text-xl text-green-600">{subTotal}</div>
          </div>
          {promotionsTotal &&
            <div className="flex flex-wrap items-center justify-between mt-2">
              <div className="text-red-dark">{window.shopTranslations.promotionsTotal}</div>
              <div className="text-red-dark font-bold">{promotionsTotal}</div>
            </div>
          }
          <OrderButton
            url={checkoutUrl}
            label={window.shopTranslations.cartCheckoutLabelBottom}
            clickHandler={this.props.openCheckout}
          />
        </div>
        <ul className="md:mt-6 usp usp--small">
          {window.cartUsps.map((item, key) => (
            <li className="mt-2 first:mt-0 flex flex-wrap items-center" key={`usp-item-${key}`}>
              {renderHTML(item)}
            </li>
          ))}
        </ul>
        <div className="mt-6 text-[#5E6973]">
          <PaymentOptions/>
        </div>
        <div className="mt-6 pt-6 border-t-2 border-gray-200">
          <div className="font-bold">{window.shopTranslations.cartTotalArticles}</div>
          <div className="mt-2">{subTotal}</div>
          <div className="font-bold mt-4">{window.shopTranslations.cartShippingCosts}</div>
          <div className="mt-2">
            {calculatedShippingCost}
          </div>
          <div className="font-bold mt-4">{window.shopTranslations.addDiscountCode}</div>

          <CartDiscounts
            discountCode={discountCode}
            activeDiscountCode={activeDiscountCode}
            discountErrors={discountErrors}
            setDiscountCode={this.props.setDiscountCode}
            handleAddDiscountCode={this.props.handleAddDiscountCode}
            removeDiscountCode={this.props.removeDiscountCode}
          ></CartDiscounts>
        </div>
        <div className="mt-6 pt-6 border-t-2 border-gray-200">
          <div className="flex flex-wrap items-center justify-between">
            <div className="font-bold text-xl">{window.shopTranslations.cartTotal}</div>
            <div className="font-bold text-xl text-green-600">{total}</div>
          </div>
          {promotionsTotal &&
            <div className="flex flex-wrap items-center justify-between">
              <div className="text-red-dark">{window.shopTranslations.promotionsTotal}</div>
              <div className="text-red-dark font-bold">{promotionsTotal}</div>
            </div>
          }
        </div>
        <div className="mt-4">
          <OrderButton
            url={checkoutUrl}
            label={window.shopTranslations.cartCheckoutLabelBottom}
            clickHandler={this.props.openCheckout}
          />
        </div>
        <div className="mt-2">
          <a
            href={window.productPageUrl}
            className="underline text-gray-500 text-sm hover:text-green-500"
          >
            { window.shopTranslations.alertContinue }
          </a>
        </div>
      </>
    );
  }
}

export default CartTotal;
