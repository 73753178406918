export default class CurrencyHelper {
  static GetFormattedPrice = (priceObject) => {
    const {language} = window;

    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: priceObject.currencyCode,
    });
    return formatter.format(priceObject.amount);
  };
}
