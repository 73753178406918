import { gql } from '@apollo/client';

export const GET_PRODUCT_VARIANTS = gql`
  query getProductById($id: ID!) {
    product(id: $id) {
      title
      options {
        id,
        name,
        values
      },
      variants(first: 15) {
        edges {
          node {
            id,
            title,
            selectedOptions{
              name
              value
            }
            image {
              altText
              id
              url(transform:{maxWidth: 1230, maxHeight: 1230})
            }
            priceV2 {
              amount,
              currencyCode
            },
            compareAtPriceV2 {
              amount,
              currencyCode
            },
            quantityAvailable
            availableForSale
            currentlyNotInStock
          }
        }
      }
    }
  }
`;
